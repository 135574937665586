import React, {Component} from "react";
import { styled } from "@mui/material/styles";
import { RootState } from '../../store/configure-store';
import { compose } from "redux";
import { connect } from 'react-redux';
import { AccessDeniedAlert } from "../../components/access-denied";
import { Loader } from '../../components/loaders/loader';
import { LoaderMini } from '../../components/loaders/loader-mini';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import TablePagination from '@mui/material/TablePagination';
import {
    apiInfoFetchList,
    apiInfoFetchCurrencyList,
    apiInfoFetchPMMethodsList,
} from '../../actions/apiInfo';

import {getTransactionsCatalogFetchList} from '../../actions/transactionsCatalog';

import {getPaymentSystemsListRequest} from '../../actions/paymentSystems';
// import {FilterListSvg} from "../../components/svg/filter-list-svg";
// import { TableFilter } from "../../components/table-filter";
// import Menu from '@mui/material/Menu';
// import SvgIcon from '@mui/material/SvgIcon';
import {Button} from '../../components/button';
// import AddLinkIcon from '@mui/icons-material/AddLink';
import DownloadIcon from '@mui/icons-material/Download';
import {CopyClipboardButton} from '../../components/clipboard-copy/button-copy';
// import Tooltip from '@mui/material/Tooltip';
// import {SerachTableHeader} from '../../components/search-table-header';
import {isJson} from '../../utils';
import {baseURL} from '../../services/api/_axiosBaseUrl';
import UpdateIcon from '@mui/icons-material/Update';


const WrapperPage = styled('div')(({theme}) => ({}));

const PageContainer = styled('div')(({theme}) => ({
    padding: '40px 40px 0 40px',
    background: theme.palette.background.paper,
    height: 'calc(100% - 80px)',
    position: 'relative',

    '@media(max-width: 768px)' : {
        padding: '16px',
        height: '100%'
    }
}));

const DepositMainHeaderText = styled('div')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    lineHeight: '38px',
    marginRight: '24px',
    color: theme.palette.text.primary,

    '@media(max-width: 768px)' : {
        fontSize: '20px',
    }
}));

const DepositMainHeader = styled('div')({
    display: 'flex',
    marginBottom: '32px',

    '@media(max-width: 768px)' : {
        justifyContent: 'space-between',
        marginBottom: '24px',
    }
});

const DepositMainSecondRow = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '32px',

    '@media(max-width: 1260px)' : {
        flexWrap: 'wrap',
        marginBottom: '24px',

    }
});

const DepositMainSecondRowRightBlock = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
});

// const DepositMainSecondRowLeftButtons = styled('div')({
//     '@media(max-width: 768px)' : {
//         '& button': {
//             boxSizing: 'border-box',
//             fontSize: '12px',
//             height: '30px',
//             padding: '0 12px',
//             marginRight: '11px',
//             '&:last-child':{
//                 marginRight: '0px',
//             }
//         }
//     }
// });

const StyledTableHead = styled(TableHead)(({ theme }) => (`
    background: ${theme.palette.background.default};
    position: sticky;
    z-index: 10;
    top: 0;
    & th{
        border-left: ${theme.palette.mode === 'dark' ? '1px solid rgba(81, 81, 81, 1)' : '1px solid rgba(224, 224, 224, 1)'};
    }   
`));

const StyledPaperTable = styled(Paper)(({ theme }) => (`
    border-radius: 0;
    border: 1px solid ${ theme.palette.mode === 'light' ? '#E3E8EC' : 'rgba(255,255,255, .2)'};
    box-sizing: border-box;
    box-shadow: none;
`));

const StyledTableContainer = styled(TableContainer)(({ theme }) => (`
    position: relative;
    .MuiPaper-root{
        border-radius: 0;
        box-sizing: border-box;
        box-shadow: none;
    }
    & .overlay-scroll-table{
        max-height: calc(100vh - 260px - 56px);
        @media(max-width: 767px){
            max-height: calc(100vh - 250px - 56px);
        }; 
    }
`));

// const TextDescription = styled('div')(({ theme }) => ({
//     color: theme.palette.text.primary,
// }));

// const TableCellWithActiveClass = styled(TableCell)(({ theme }) => ({
//     '&.activeFilterClass':{
//         background: '#38AA72',
//         '& div':{
//             color: '#fff',
//             '& svg path':{
//                 fill: '#fff',
//             }
//         }
//     }
// }));

// const TableCellContent = styled('div')(`
//     display: flex;
//     align-items: center;
// `);

// const IconWrapper = styled('div')(`
//     margin-left: 8px;
//     cursor: pointer;
//     display: flex;
//     align-items: center;
// `);

// const StyledMenu = styled(Menu)(({ theme }) => (`
//     .MuiPaper-root{
//         border-radius: 0;
//         & > .MuiList-root{            
//             padding: 0 0 8px 0;
//         }
//     }
// `));

// const WithSortedTableCellContent = styled(TableCellContent)(({ theme }) => (`
//     position: relative;
//     // padding-right: 30px;
//     min-width: 120px;
//     widt: 25%;
//     box-sizing: border-box;
//     justify-content: flex-start;
// `));

// const SortButtons = styled('div')(({ theme }) => (`
//     position: absolute;
//     right: 0;
//     top: -10px;
// `));

// const SortButtonsSongleToLeft = styled(SortButtons)(({ theme }) => (`
//     position: absolute;
//     right: 0;
//     top: -10px
// `));

// const SortButtonTop = styled('div')(({ theme }) => (`
//     & svg{ 
//         cursor: pointer;
//         bottom: -7px;
//         position: relative;
//         width: 12px;
//         height: 12px;
//     }
// `));

// const SortButtonBottom = styled('div')(({ theme }) => (`
//     & svg{ 
//         cursor: pointer;
//         position: relative;
//         top: -5px;
//         width: 12px;
//         height: 12px;
//     }
// `));

const WrapActions = styled('div')(({ theme }) => (`
    display: flex;
    justify-content: center;
`));

const LoaderPreDownloadElements = styled('div')(({ theme }) => (`
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: relative;
`));

// type data = {
//     'paymentSystem.id[]': number,
//     'page': number,
//     'way[]': string,
//     'currency[]': Array<string>,
//     'paymentMethod[]': Array<string>,
//     'order[dateLastTransaction]': string,
//     'itemsPerPage': number,
// }

type PropsType = {
    isLoading: boolean, 
    totalItems: number,
    routsUser: Array<any>,
    userRoles: Array<any>,
    isSuperadmin: number,
    apiInfoFetchList: (data: any) => void,
    apiInfoFetchCurrencyList: () => void,
    apiInfoFetchPMMethodsList: () => void,
    getPaymentSystemsListRequest: () => void,
    getTransactionsCatalogFetchList: (data: any) => void,
    transactionReportsList?: any,
    userID: number,
}

type StateProps = {
    page: number,
    // rowsPerPage: number,
    anchorElWay: null | any,
    way: Array<string>,
    anchorElCurrency: null | any,
    currency: Array<string>,
    anchorElPaymentMethod: null | any,
    paymentMethod: Array<string>,
    anchorElPaymentSystems: null | any,
    paymentSystems: Array<string>,
    oeder: string,
    itemsPerPage: number,
}

// const filterItemsWay = [
//     {title: 'Deposit', value: 'deposit'}, 
//     {title: 'Withdraw', value: 'withdraw'}, 
// ];


export class TransactionsReportsCatalog extends Component<PropsType, StateProps> {
    constructor(props) {
        super(props);

        this.state = {
            page: 0,
            // rowsPerPage: 20,
            anchorElWay: null,
            way: [],
            anchorElCurrency: null,
            currency: [],
            anchorElPaymentMethod: null,
            paymentMethod: [],
            anchorElPaymentSystems: null,
            paymentSystems: [],
            oeder: 'desc',
            itemsPerPage: 50,
        }
    };

    handleChangePage = (event: React.MouseEvent<HTMLElement>, newPage: number) => {
        this.setState({page: newPage}, () => {
            this.updateData();
        });
    };

    handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({page: 0, itemsPerPage: parseInt(event.target.value)}, () => {this.updateData()})
    };

    handleSearchSubmit = (prop) => (value) => {
        //@ts-ignore
        this.setState({[prop]: value, page: 0}, () => {this.updateData()});
    };
    handleCancelSearch = (prop) => () => {
        //@ts-ignore
        this.setState({[prop]: '', page: 0}, () => {this.updateData()});
    };

    handleWayMenuOpen = (e: React.MouseEvent<HTMLElement>) => {
        this.setState({anchorElWay: e.currentTarget});
    }
    handleWayMenuClose = () => {
        this.setState({anchorElWay: null});
    }
    onChangeWayFilter = (data) => {
        this.setState({way: data}, () => {this.updateData()});
        this.handleWayMenuClose();
    }

    handleCurrencyMenuOpen = (e: React.MouseEvent<HTMLElement>) => {
        this.setState({anchorElCurrency: e.currentTarget});
    }
    handleCurrencyMenuClose = () => {
        this.setState({anchorElCurrency: null});
    }
    onChangeCurrencyFilter = (data) => {
        this.setState({currency: data}, () => {this.updateData()});
        this.handleCurrencyMenuClose();
    }

    handlePaymentMethodMenuOpen = (e: React.MouseEvent<HTMLElement>) => {
        this.setState({anchorElPaymentMethod: e.currentTarget});
    }
    handlePaymentMethodMenuClose = () => {
        this.setState({anchorElPaymentMethod: null});
    }
    onChangePaymentMethodFilter = (data) => {
        this.setState({paymentMethod: data}, () => {this.updateData()});
        this.handlePaymentMethodMenuClose();
    }

    handlePaymentSystemsMenuOpen = (e: React.MouseEvent<HTMLElement>) => {
        this.setState({anchorElPaymentSystems: e.currentTarget});
    }
    handlePaymentSystemsMenuClose = () => {
        this.setState({anchorElPaymentSystems: null});
    }
    onChangePaymentSystemsFilter = (data) => {
        this.setState({paymentSystems: data}, () => {this.updateData()});
        this.handlePaymentSystemsMenuClose();
    }

    handleClickSortTopUpdate = () => {
        this.setState({oeder: 'asc'}, () => this.updateData())
    };

    handleClickSortBottomUpdate = () => {
      this.setState({oeder: 'desc'}, () => this.updateData())
    };

    handleClearFilters = () => {
        this.setState({
                page: 0,
                anchorElWay: null,
                way: [],
                anchorElCurrency: null,
                currency: [],
                anchorElPaymentMethod: null,
                paymentMethod: [],
                anchorElPaymentSystems: null,
                paymentSystems: [],
                oeder: 'desc',
            }, 
            () => {this.updateData()}
        )
    }
    
    componentDidMount() {
        const {
            routsUser, 
            userRoles, 
            isSuperadmin,
        } = this.props;

        if(routsUser.some(rout => rout.child.includes('/transaction/')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin){
            this.updateData();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            routsUser, 
            userRoles, 
            isSuperadmin,
        } = this.props;

        if (prevProps.userID !== this.props.userID) {
            if(routsUser.some(rout => rout.child.includes('/transaction/')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin){
                this.updateData();
            }
        };
    };

    updateData = () => {
        const {
            page,
            itemsPerPage,
        } = this.state;

        const {
            getTransactionsCatalogFetchList,
        } = this.props;

        const data = {
            'page': page + 1,
            'itemsPerPage': itemsPerPage,
        };

        Object.keys(data).forEach(key => {
            if (!data[key]) delete data[key];
        });

        getTransactionsCatalogFetchList(data);
    }

    handleUpdateData = () => {
        this.updateData();
    }

    isClearedFilters = () => {
        const {                
            page,
            way,
            currency,
            paymentMethod,
            paymentSystems,
        } = this.state
        return !(page > 0 || way.length || currency.length || paymentMethod.length || paymentSystems.length)
    }

    render() {

        const {
            isLoading,
            routsUser, 
            userRoles, 
            isSuperadmin,
            totalItems,
            transactionReportsList,
            // userID
        } = this.props

        const {
            page,
            itemsPerPage,
        } = this.state;

        const accessedRole = routsUser.some(rout => rout.child.includes('/transaction/')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;

        // console.log(userID);

        return (
            <WrapperPage>
                <PageContainer>

                    {!accessedRole && <AccessDeniedAlert/>}

                    {accessedRole &&
                        <>
                            <DepositMainHeader>
                                <DepositMainHeaderText>Transactions reports</DepositMainHeaderText>
                            </DepositMainHeader>

                            <DepositMainSecondRow>
                                <DepositMainSecondRowRightBlock>
                                    <Button
                                        variant="contained"
                                        onClick={this.handleUpdateData}
                                    >   
                                        Update
                                        <UpdateIcon sx={{marginLeft: '8px'}}/>
                                    </Button>
                                </DepositMainSecondRowRightBlock>
                            </DepositMainSecondRow>
                            <StyledPaperTable>                               
                                <StyledTableContainer>

                                    <OverlayScrollbarsComponent
                                        className='overlay-scroll-table'
                                        options={{
                                            scrollbars: { visibility: 'visible' } 
                                        }}
                                    >

                                        <Table>
                                            <StyledTableHead>
                                                <TableRow>

                                                    <TableCell sx={{"minWidth": '150px'}}>
                                                        Date create
                                                    </TableCell>

                                                    <TableCell sx={{"minWidth": '150px'}}>
                                                        Date update
                                                    </TableCell>

                                                    <TableCell sx={{"minWidth": '150px'}}>
                                                        Generation time
                                                    </TableCell>

                                                    <TableCell width={250}>
                                                        Id
                                                    </TableCell>

                                                    {!!isSuperadmin &&
                                                        <TableCell width={250}>
                                                            User
                                                        </TableCell>
                                                    }

                                                    <TableCell width={400}>
                                                        Description
                                                    </TableCell>

                                                    <TableCell>
                                                        Action
                                                    </TableCell>

                                                </TableRow>
                                            </StyledTableHead>

                                            <TableBody>
                                                {
                                                    (transactionReportsList || []).map((item, i) => {
                                                        return  <TableRow key={i}>
                                                            <TableCell>
                                                                {item.createdAt}
                                                            </TableCell>

                                                            <TableCell>
                                                                {item.updatedAt}
                                                            </TableCell>

                                                            <TableCell>
                                                                {
                                                                    item.hasOwnProperty('url')
                                                                        ? item.hasOwnProperty('diffDateStr') && item.diffDateStr === '' ? '0s' : item.diffDateStr   
                                                                        : <LoaderPreDownloadElements><LoaderMini/></LoaderPreDownloadElements> 
                                                                }
                                                            </TableCell>

                                                            <TableCell>
                                                                {item.id}
                                                            </TableCell>

                                                            {!!isSuperadmin &&
                                                                <TableCell>
                                                                    {item?.user?.username}
                                                                </TableCell>
                                                            }

                                                            <TableCell>
                                                                {
                                                                    isJson(item.params) 
                                                                        ? Object.keys(JSON.parse(item.params)).map((key, y) => {
                                                                            return <div key={y}>
                                                                                {key}: {
                                                                                    Array.isArray(JSON.parse(item.params)[key])
                                                                                        ? JSON.parse(item.params)[key].map((itemKey, iy) => {
                                                                                            return <span key={iy}>{`${itemKey} `}</span>
                                                                                        })
                                                                                        : JSON.parse(item.params)[key]
                                                                                }
                                                                            </div>
                                                                        })
                                                                    : ''
                                                                }
                                                            </TableCell>

                                                            <TableCell width={250}>
                                                                <WrapActions>
                                                                    { item.hasOwnProperty('url') &&
                                                                        <>
                                                                            <a 
                                                                                href={`${baseURL.slice(0, -1)}${item.url}`} 
                                                                                target="_blank"
                                                                                rel="noreferrer"
                                                                            >
                                                                                <Button
                                                                                    variant="outlined"
                                                                                >
                                                                                    <DownloadIcon sx={{marginRight: '0px'}}/>
                                                                                </Button>
                                                                            </a>
                                                                            <CopyClipboardButton 
                                                                                sx={{marginLeft: '16px'}} 
                                                                                data={`${baseURL.slice(0, -1)}${item.url}`}
                                                                            />
                                                                        </>
                                                                        // <>
                                                                        //     <LoaderPreDownloadElements>
                                                                        //         <LoaderMini/>
                                                                        //     </LoaderPreDownloadElements>   
                                                                        // </>
                                                                    }
                                                                </WrapActions>
                                                            </TableCell>
                                                        </TableRow>
                                                    })
                                                }
                                            </TableBody>
                                        </Table>    

                                    </OverlayScrollbarsComponent>

                                </StyledTableContainer>     

                                <TablePagination
                                    rowsPerPageOptions={[20, 50, 75, 100]}
                                    component="div"
                                    count={totalItems}
                                    rowsPerPage={itemsPerPage}
                                    page={page}
                                    onPageChange={this.handleChangePage}
                                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                                />
                            </StyledPaperTable>
                        </>
                    }

                    { isLoading && <Loader/> }
    
                </PageContainer>
            </WrapperPage>
        )
    }

}

export default compose(
    connect((state: RootState) => ({
        isLoading: state.apiTransactionsCatalog.isLoading, 
        transactionReportsList: state.apiTransactionsCatalog.apiCatalogList['hydra:member'],
        totalItems: state.apiTransactionsCatalog.apiCatalogList['hydra:totalItems'],
        userID: state.currentUser.userData.id,
        routsUser: state.currentUser.userData.routs,
        userRoles: state.currentUser.userData.roles,
        isSuperadmin: state.currentUser.userData.superadmin,
    }), {
        apiInfoFetchList,
        apiInfoFetchCurrencyList,
        apiInfoFetchPMMethodsList,
        getPaymentSystemsListRequest,

        getTransactionsCatalogFetchList,
    }),
)(TransactionsReportsCatalog)
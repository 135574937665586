import CryptoJS from 'crypto-js';

// eslint-disable-next-line
export const decriptCryptoJSstring = (encryptedString, keyString, ivHex) => {
    const key = CryptoJS.lib.WordArray.create(CryptoJS.enc.Utf8.parse(keyString).words, 32);
    const iv = CryptoJS.enc.Hex.parse(ivHex); 
    const encryptedWordArray = CryptoJS.enc.Base64.parse(encryptedString);
    const decrypted = CryptoJS.AES.decrypt(
        { ciphertext: encryptedWordArray },
        key,
        {
            iv: iv, 
            mode: CryptoJS.mode.CBC, 
            padding: CryptoJS.pad.Pkcs7, 
        }
    );
    const decryptedString = decrypted.toString(CryptoJS.enc.Utf8);
    return decryptedString
}

import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {ArrowBackSvg} from "../../components/svg/arrow-back-svg";
import Structure from '../../components/structure';
import {Button} from '../../components/button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {SwitchComponent} from '../../components/switch';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import Tabs from '@mui/material/Tabs';
import TabPanel from '@mui/lab/TabPanel';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
    getUserPaymentSystemRequest, 
    changeFieldCredentials, 
    changeFieldCurrencies,
    changeFieldCredentialsModeModal,
    changeActiveFieldCredentialsModeModal,
    updateUserPaymentSystem,
    updateUserPaymentSystemWithAccounts,
} from '../../actions/userPaymentSystems';
import {connect} from 'react-redux';
import {compose} from "redux";
import {RootState} from '../../store/configure-store';
import {Loader} from '../../components/loaders/loader';
import {Input} from '../../components/input';
import _ from 'lodash'
import {useParams, useNavigate} from 'react-router-dom';
import {NotFoundPage} from '../not-found-page';

// import { useAppSelector } from '../../app/hooks';
import { AccessDeniedAlert } from "../../components/access-denied";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import CreateNewAccountForPaymentSystem from './add-new-accaunt';
import Modal from '@mui/material/Modal';
import { NotifyDialog } from '../../components/notify-dialog';
import Dialog from '@mui/material/Dialog';

import {decriptCryptoJSstring} from '../../utils/crypto-js';
const ENV_CRTPTOKEY = process.env.REACT_APP_CRYPTOKEY;
const ENV_IVHEX = process.env.REACT_APP_IVHEX;


const PageContainer = styled('div')(({theme}) => (`  
    position: relative;
    padding: 40px;
    background: ${theme.palette.background.paper};
    height: calc(100% - 80px);
    @media(max-width: 768px) {
        padding: 16px;
        height: 100%;
    }
`));

const ShortViewHeader = styled('div')(`
    position: relative;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    flex-wrap: wrap;
    @media(max-width: 768px){
        flexWrap: wrap;
    }
`); 

const ShortViewHeaderText = styled('span')(({ theme }) => (`
    font-size: 30px;
    font-weight: 500;
    line-height: 38px;
    color: ${theme.palette.text.primary};
    @media(max-width: 768px){
        font-size: 20px;
        width: 100%;
    }
`));

const ShortViewHeaderLeft = styled('div')(`
    display: flex;
    align-items: center;
    gap: 24px;
    @media(max-width: 1300px){
        width: 100%;
        margin-bottom: 32px;
    };
    @media(max-width: 768px){
        justify-content: space-between;
        width: 100%;
        margin-bottom: 24px;
        gap: 16px;
        flex-wrap: wrap;
    }
`);

const ShortViewHeaderRight = styled('div')(`
    display: flex;
    gap: 16px;
    @media (max-width: 768px){
        gap: 12px;
    }
    @media (max-width: 360px){
        gap: 10px;
    }
`);

const HeaderLeftContainer = styled('div')(`
    display: flex;
    align-items: center;
    @media(max-width: 768px){
        width: 100%;
    }
`);

const ArrowContainer = styled('div')(`
    cursor: pointer;
    margin-right: 24px;
    display: flex;
    align-items: center;
`);

const StyledTab = styled(Tab)(({ theme }) => (`
    &.MuiTab-root{
        color: #979799;
    }
    &.Mui-selected{
        color: ${theme.palette.text.primary};
    }
`));

const StyledTabs = styled(Tabs)(`
    & .MuiTabs-indicator{
        background-color: #272F3D;
    },
`);

const StyledTabPanel = styled(TabPanel)(`
    padding: 0px 0px 30px 0px;
    margin-top: 24px;
`);

const WrapFields = styled('div')(({ theme }) => (``));

const StyledFormControl = styled(FormControl)(({ theme }) => (`
    margin-top: 20px;
    width: 100%;
    & label.Mui-focused{
        color: #38AA72;
    }
    & .MuiOutlinedInput-root {
        &.Mui-focused fieldset {
            border-color: #38AA72;
        }
    }   
`));

const StyledMenuItem = styled(MenuItem)(({ theme }) => (`
    &:hover{
        background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
    }
    &.Mui-selected{
        background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
        &:hover{
            background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
        };
        &.Mui-focusVisible{
            background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'}
        };
    }
`));

const TopSortedButton = styled('div')(({theme}) => (`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;
`));

const StyledPaperTable = styled(Paper)(({ theme }) => (`
    border-radius: 0;
    border: 1px solid ${ theme.palette.mode === 'light' ? '#E3E8EC' : 'rgba(255,255,255, .2)'};
    box-sizing: border-box;
    box-shadow: none;
    border-bottom: none;
`));

const StyledTableHead = styled(TableHead)(({ theme }) => (`
    background: ${theme.palette.background.default};
`));

const StyledCheckbox = styled(Checkbox)(({ theme, disabled = false }) => (`
    color: #979799;
    &.Mui-checked{
        color: ${disabled ? 'rgba(0, 0, 0, 0.38)' : '#38AA72'};
    }
`));

const WithBorderLeftTableCell = styled(TableCell)(({theme}) => (`
    border-left:  ${theme.palette.mode === 'dark' ? '1px solid rgba(81, 81, 81, 1)' : '1px solid rgba(224, 224, 224, 1)'};
`));

const WithBorderLeftTableCellPD = styled(TableCell)(({theme}) => (`
    border-left: ${theme.palette.mode === 'dark' ? '1px solid rgba(81, 81, 81, 1)' : '1px solid rgba(224, 224, 224, 1)'};
    padding: 0;
`));

const StyledThTableCell = styled(TableCell)(({ theme }) => (`
    padding-top: 0; 
    padding-bottom: 0; 
    border-bottom: none;

`));

const StyledThTableCellAlignTop = styled(TableCell)(({ theme }) => (`    
    vertical-align: top;
    & .MuiCheckbox-root{
        margin-top: 5px;
    }
`));

const WithBorderBottomTableRow = styled(TableRow)(({theme}) => (`
    border-bottom: ${theme.palette.mode === 'dark' ? '1px solid rgba(81, 81, 81, 1)' : '1px solid rgba(224, 224, 224, 1)'};
    &:last-child{
        border-bottom: none; 
    }
`));

const StyledMininizeTableCell = styled(TableCell)(({theme}) => (`
    padding: 9px 16px;
`));

const WrapCurrencyTab = styled('div')(({theme}) => (`
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 10px;
    width: 100%;
    // max-width: 500px;
`));

const WrapConfigureFormContainer = styled('div')(({ theme }) => (`
    width: 100%;
    max-width: 900px;
`));

const ButtonText = styled(Button)(({theme}) => (`
    border: none !important;
    font-weight: 500;
    height: 30px;
    margin-right: 0px;
    padding-left: 5px;
    padding-right: 5px;
    &:disabled{        
    }
`));

const CurrencyTab = styled(ButtonText)(({theme}) => (`
    font-size: 14px;
    color: ${theme.palette.mode === 'dark' ? theme.palette.text.primary : '#797780'}; 
    margin: 6px 6px 0 0; 
    font-weight: 500;
    &.MuiButton-text{
        background: #F1F8ED;
        border-radius: 6px;
        color: #38AA72;
    }
`));

const WrapTabsAccounts = styled('div')(({ theme }) => (`
    display: flex;
    align-items: center;
`));

const WrapTabsList = styled('div')(({ theme }) => (`
    // flex: 1;
    padding-right: 6px;
`));

const WrapTabsButtonAdd = styled('div')(({ theme }) => (`
    // flex: 0;
    padding-top: 6px;
`));

const AccountTab = styled(ButtonText)(({theme}) => (`
    font-size: 14px;
    color: ${theme.palette.mode === 'dark' ? theme.palette.text.primary : '#797780'}; 
    margin: 6px 6px 0 0; 
    font-weight: 500;
    text-transform: none;
    &.MuiButton-text{
        background: #F1F8ED;
        border-radius: 6px;
        color: #38AA72;
        text-transform: none;
    }
`));

const AccountTitle = styled('h4')(({ theme }) => (`
    color: ${theme.palette.text.primary};
`));

const AccountDelete = styled(Button)(({ theme }) => (`
    color: #fff;
    height: 32px;
    min-width: 32px;
    padding-left: 0;
    padding-right: 0;
`));

const AccountAdd = styled(Button)(({ theme }) => (`
    color: #fff;
    height: 32px;
    min-width: 32px;
    padding-left: 0;
    padding-right: 0;
`));

const WrapAccountTopSection = styled('div')(({ theme }) => (`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
`));

const StyledModal = styled(Modal)(({ theme }) => (`
    display: flex;
    justify-content: center;
    align-items: center;
`));

const StyledBoxPopup = styled(Box)(({ theme }) => (`
    & {
        box-sizing: border-box;
        width: 100%;
        max-width: 550px;
        margin:0 auto;
        background: ${theme.palette.background.paper};
        @media screen and (max-height: 660px){
            overflow-y: scroll;
        }
    }
`));

const TextTitleCurrency = styled('div')(({ theme }) => (`
    color: ${theme.palette.text.primary};
    font-size: 20px;
    font-weight: 600;
    padding-left: 5px;
    padding-top: 16px;
    padding-bottom: 8px;
`));

const StyledDialog = styled(Dialog)(({ theme }) => (`
    &{
        .MuiDialog-paper{
            border-radius: 0;
        }
    }
`));

const WrapDiv = styled('div')(({ theme }) => (``));

type SteteLocal = {
    ModeModel?: [
        {
            account_id: string | number,
            fields: [
                {
                    attribute: string,
                    value: any,
                    field: {
                        label: string,
                        type: string,
                        required: null | boolean,
                        values: null | string | boolean,
                        focused?: boolean,
                    }
                }
            ]                   
        }
    ],
    Model?: [
        {
            account_id: string | number,
            fields: Array<any>
        }
    ],
    UserPaymentSystem: {},
    credentials: [],
    currencies: Array<any>,
    model: any,
    accounts?: number,
    modelName: string,
}

export const ChangePaynemtSystem = (props: any) => {   

    const {    
        getUserPaymentSystemRequest,
        paymentSystem,
        isLoading,
        updateUserPaymentSystemWithAccounts,
        error,
        routsUser,
        userRoles,
        isSuperadmin,
    } = props;

    const [stateUpdate, setStateUpdate] = useState<SteteLocal>({
        ModeModel: [
            {
                account_id: '',
                fields: [
                    {
                        attribute: "",
                        value: "",
                        field: {
                            label: "",
                            type: "",
                            required: null,
                            values: null
                        }
                    }
                ],
            }
        ],
        UserPaymentSystem: {},
        credentials: [],
        currencies: [],
        modelName: '',
        accounts: 0,
        model: {
            active: 1,
        },
    })






    useEffect(() => {

        if(paymentSystem.accounts === 0){
            if(paymentSystem.hasOwnProperty('ModeModel')){
                try{
                    paymentSystem.ModeModel.forEach(el => {
                        el.fields.forEach(field => {
                            field.value = field.value ? decriptCryptoJSstring(field.value, keyString, ivHex) : field.value
                        })
                    });
                }catch(err){}
            }

            if(paymentSystem.hasOwnProperty('Model')){
                try{
                    paymentSystem.Model.forEach(elAccount => {
                        for (let key in elAccount.fields){
                            paymentSystem.Model[0].fields[key].forEach(itemCurrField => {
                                itemCurrField.value = itemCurrField.value ? decriptCryptoJSstring(itemCurrField.value, keyString, ivHex) : itemCurrField.value
                            })
                        }
                    })
                }catch(err){}
            }
        }

        if(paymentSystem.accounts > 0){
            if(paymentSystem.hasOwnProperty('ModeModel')){
                try{
                    paymentSystem.ModeModel.forEach(elAccount => {
                        elAccount.fields.forEach(field => {
                            field.value = field.value ? decriptCryptoJSstring(field.value, keyString, ivHex) : field.value
                        })
                    })
                }catch(err){}
            }

            if(paymentSystem.hasOwnProperty('Model')){
                try{
                    paymentSystem.Model.forEach(elAccount => {
                        for (let currency in elAccount.fields){
                            elAccount.fields[currency].forEach(itemCurrField => {
                                itemCurrField.value = itemCurrField.value ? decriptCryptoJSstring(itemCurrField.value, keyString, ivHex) : itemCurrField.value
                            });
                        }
                    })
                }catch(err){}
            }
        }

        setStateUpdate(paymentSystem)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentSystem])





    // const roleForDisableChange = useUserRoleTmpRouts.some(role => role.name === 'support_level' || 'support level');
    const roleForDisableChange = !(routsUser.some(rout => rout.child === '/user-payment-system/configure') || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin);
    const accessedRoleView = routsUser.some(rout => rout.child.includes('/user-payment-system/')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;

    const {id} = useParams();
    const navigate = useNavigate();

    const [tabValue, setTabValue] = React.useState('1');

    const [isClearEnabled, setAsClearEnabled] = React.useState<boolean>(true)

    const [secondsTansIndex, setSecondsTansIndex] = React.useState<number | any>(0);

    const [accountsTaabIndex, setAccounysTabsIndex] = React.useState<number | any>(0);

    const [openCreatePopup, setOpenCreatePopup] = React.useState(false);

    useEffect(() => {
        if(accessedRoleView){
            getUserPaymentSystemRequest(id)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleBack = () => {
        navigate(-1)
    }

    const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };

    // Structured methods currensi tab
    const sortedMethodsCurrency = (resp) => {
        const unicCurrencyField = _.uniq((resp || []).map((item) => {
            return item.field.split('_')[0].toUpperCase();
        }))
        const structuredCurrency = unicCurrencyField.map((itemCurrency) => {
            const fields = resp.filter((itemField) => itemField.field.split('_')[0].toUpperCase() === itemCurrency)
            fields.forEach(item => item.labels = item.label.split(' - '))
            return {
                currency: itemCurrency,
                methodsCurrency: fields,
                isAllChecked: fields.every((item) => item.checked)
            }    
        })
        return structuredCurrency
    }
    
    const handleChangePaymentSystemCurrencyes = (item, e) => {
        setStateUpdate(prev => {
            let lockPrev = _.cloneDeep(prev)
            lockPrev.currencies.map(currencyItem => {
                if(currencyItem.field === item.field){
                    currencyItem.checked = !item.checked
                    return currencyItem
                }
                return currencyItem
            })
            return {...lockPrev}
        });
    }

    const getChangeSatateModeModelNoAccount = (input, e) => {
        setStateUpdate(prev => {
            let lockPrev = _.cloneDeep(prev)
            lockPrev.ModeModel.forEach(item => {
                item.fields.forEach(itemField => {
                    if(itemField.attribute === input.attribute){
                        if(itemField.field.type === 'checkbox'){
                            let val =  itemField.field.type === 'checkbox' ? Number(!Boolean(input.value)) : e.target.value;
                            itemField.value = val;
                        } else {
                            itemField.value = e.target.value;
                        }
                    }
                })
                return item
            })
            return {...lockPrev}
        })
    }

    const handleChangePaymentSystemCredentials = (item, e) => {
        getChangeSatateModeModelNoAccount(item, e)
    }

    const getChangeSatateModelNoAccount = (currency, item, e) => {
        setStateUpdate(prev => {
            let lockPrev = _.cloneDeep(prev)
            lockPrev.Model.forEach((accountID, i) => {

                lockPrev.Model[i].fields[currency].forEach(itemFieldCurrency => {
                    if(itemFieldCurrency.attribute === item.attribute){
                        if(itemFieldCurrency.field.type === 'checkbox'){
                            itemFieldCurrency.value = !Boolean(itemFieldCurrency.value)
                        } else {
                            itemFieldCurrency.value = e.target.value;
                        }
                    }
                })
                
            })
            return {...lockPrev}
        });
    }

    const handleChangePaymentSystemCredentialsModel = (itemCurrency, item, e) => {
        getChangeSatateModelNoAccount(itemCurrency, item, e)
    }

    // Validation after focise Model
    const handleChangeFocuseModel = (currency, item) => {
        setStateUpdate(prev => {
            let lockPrev = _.cloneDeep(prev)
            lockPrev.Model.forEach((accountID, i) => {

                lockPrev.Model[i].fields[currency].forEach(itemFieldCurrency => {
                    if(itemFieldCurrency.attribute === item.attribute){
                        itemFieldCurrency.field.focused = true;
                    }
                })
                
            })
            return {...lockPrev}
        });
    }

    // Validation after focise ModeModel
    const handleChangeFocuseModeModel = (input) => {
        setStateUpdate(prev => {
            let lockPrev = _.cloneDeep(prev)
            lockPrev.ModeModel.forEach(item => {
                item.fields.forEach(itemField => {
                    if(itemField.attribute === input.attribute) itemField.field.focused = true;
                })
                return item
            })
            return {...lockPrev}
        })
    }

    // Validation after focise Model with Accounts
    const handleChangeFocuseAccountsModel = (itemAccount, itemCurrency, item) => {
        setStateUpdate(prev => {
            let lockPrev = _.cloneDeep(prev)
            lockPrev.Model.map(accountID => {
                if(itemAccount === accountID.account_id){
                    accountID.fields[itemCurrency].forEach(itemField => {
                        if(itemField.attribute === item.attribute) itemField.field.focused = true;
                    })
                }
                return item
            })
            return {...lockPrev}
        })
    }

    // Validation after focise ModeModel with Accounts
    const handleChangeFocuseAccountsModeModel = (itemAccount, item) => {
        setStateUpdate(prev => {
            let lockPrev = _.cloneDeep(prev)
            lockPrev.ModeModel.map(accountID => {
                if(itemAccount === accountID.account_id){
                    accountID.fields.forEach(itemField => {
                        if(itemField.attribute === item.attribute) itemField.field.focused = true;
                    })
                }
                return item
            })
            return {...lockPrev}
        })
    }

    // Payment method change
    const handleChecngePaymentSystemCurrencyCheckbox = (item, e) => {
        setStateUpdate(prev => {
            let lockPrev = _.cloneDeep(prev)
            item.methodsCurrency.every((item) => item.checked) 
                ? item.methodsCurrency.forEach(currencyItem => {
                    lockPrev.currencies.forEach(localStateCurrancyItem => {
                        if(currencyItem.field === localStateCurrancyItem.field){
                            localStateCurrancyItem.checked = false
                        }
                    })
                })
                : item.methodsCurrency.forEach(currencyItem => {
                    lockPrev.currencies.forEach(localStateCurrancyItem => {
                        if(currencyItem.field === localStateCurrancyItem.field){
                            localStateCurrancyItem.checked = true
                        }
                    })
                });
            return {...lockPrev}
        });
    }

    const handleClickMainFilterCurency = (e, ) => {
        let check = e.target.checked
        setStateUpdate(prev => {
            let lockPrev = _.cloneDeep(prev)
            lockPrev.currencies.forEach(item => item.checked = check)
            return {...lockPrev}
        });
    }

    const handleHideEnabledMethods = () => {
        setAsClearEnabled(false)
    }

    const handleHideEnabledMethodsClear = () => {
        setAsClearEnabled(true)
    }

    const handleClickCurrencyTab = (i) => {
        setSecondsTansIndex(i);
    };

    const handleClickAccountTab = (i) => {
        setAccounysTabsIndex(i)
        setSecondsTansIndex(0)
    }

    // For multiaccounts. Fields change 
    const handleChangeStateUpdateAccountsModeModel = (accountId, e, itemField) => {
        setStateUpdate(prev => {
            let lockPrev = _.cloneDeep(prev)
            lockPrev.ModeModel.map(item => {
                if(item.account_id === accountId){
                    item.fields.map(itemFieldChange => {
                        if(itemFieldChange.attribute === itemField.attribute){
                            if(itemField.field.type === 'checkbox'){
                                itemFieldChange.value = !Boolean(itemFieldChange.value)
                            } else {
                                itemFieldChange.value = e.target.value
                            }
                        }
                        return itemField
                    })
                }
                return item
            })
            return {...lockPrev}
        })
    }

    const handleChangeStateUpdateAccountsModel = (itemAccount, itemCurrency, item, e) => {
        setStateUpdate(prev => {
            let lockPrev = _.cloneDeep(prev)
            lockPrev.Model.map(accountID => {
                if(itemAccount === accountID.account_id){
                    accountID.fields[itemCurrency].forEach(itemField => {
                        if(itemField.attribute === item.attribute){
                            if(itemField.field.type === 'checkbox'){
                                itemField.value = !Boolean(itemField.value)
                            } else {
                                itemField.value = e.target.value;
                            }
                        }
                    })
                }
                return item
            })
            return {...lockPrev}
        })
    }

    const handleChangeStateUpdateActivePS = (e) => {
        setStateUpdate(prev => {
            prev.model.active = !Boolean(prev.model.active)
            return {...prev}
        })
    }

    const handleOpenCreatePopup = () => {
        setOpenCreatePopup(true)
    }
    const handleCloseCreatePopup = () => {
        setOpenCreatePopup(false)
    }

    //Add new account for PS ModeModel
    const dataPSCreateWithMultiaccountsModeModel = (data) => {
        //@ts-ignore
        setStateUpdate(prev => {
            let lockPrev = {
                ..._.cloneDeep(prev),
                ModeModel: [
                    ..._.cloneDeep(prev).ModeModel,
                    {
                        account_id: data.name,
                        fields: [
                            ..._.cloneDeep(prev).ModeModel[0].fields.map(item => {
                                if(item.field.type === 'checkbox'){
                                    item.value = null
                                }else{
                                    item.value = ''
                                }
                                return item
                            }), 
                        ]
                    }
                ]
            }
            return lockPrev
        })
    }

    //Add new account for PS multicurrency Model
    const dataPSCreateWithMultiaccountsModel = (data) => {
        //@ts-ignore
        setStateUpdate(prev => {
            let lockPrev = {
                ..._.cloneDeep(prev),
                Model: [
                    ..._.cloneDeep(prev).Model,
                    {
                        account_id: data.name,
                        fields: prev.Model[0].fields.hasOwnProperty('common') ? {
                            'common': _.cloneDeep(prev).Model[0].fields['common'],
                            ..._.cloneDeep(prev).Model[0].fields,
                        } : {
                            ..._.cloneDeep(prev).Model[0].fields
                        }
                    }
                ]
            }

            lockPrev.Model.forEach(itemAcc => {
                if(itemAcc.account_id === data.name){
                    for (let key in itemAcc.fields) {
                        itemAcc.fields[key].forEach(item => {
                            if(item.field.type === 'checkbox'){
                                item.value = false
                            }else{
                                item.value = ''
                            }
                        })
                    }
                }
            })

            return {...lockPrev}
        })
    }

    // First account ModeModel
    const dataPSCreateFirstAccountsModeModel = (data) => {
        //@ts-ignore
        setStateUpdate(prev => {
            let lockPrev = {
                ..._.cloneDeep(prev),
                ModeModel: [
                    ..._.cloneDeep(prev).ModeModel,
                    {
                        account_id: data.name,
                        fields: [
                            ..._.cloneDeep(prev).ModeModel[0].fields.map(item => {
                                if(item.field.type === 'checkbox'){
                                    item.value = null
                                }else{
                                    item.value = ''
                                }
                                return item
                            }), 
                        ]
                    }
                ],
                accounts: 1,
            }
            return lockPrev
        })
    }

    // First account Model
    const dataPSCreateFirstAccountsModel = (data) => {
        //@ts-ignore
        setStateUpdate(prev => {
            let lockPrev = {
                ..._.cloneDeep(prev),
                Model: [
                    ..._.cloneDeep(prev).Model,
                    {
                        account_id: data.name,
                        fields: prev.Model[0].fields.hasOwnProperty('common') ? {
                            'common': _.cloneDeep(prev).Model[0].fields['common'],
                            ..._.cloneDeep(prev).Model[0].fields,
                        } : {
                            ..._.cloneDeep(prev).Model[0].fields
                        }
                    }
                ],
                accounts: 1,
            }

            lockPrev.Model.forEach(itemAcc => {
                if(itemAcc.account_id === data.name){
                    for (let key in itemAcc.fields) {
                        itemAcc.fields[key].forEach(item => {
                            if(item.field.type === 'checkbox'){
                                item.value = false
                            }else{
                                item.value = ''
                            }
                        })
                    }
                }
            })

            return {...lockPrev}
        })
    }

    const handleCreateAccount = (data) => {
        if(stateUpdate.accounts < 1){
            if(stateUpdate.ModeModel) dataPSCreateFirstAccountsModeModel(data)
            if(stateUpdate.Model) dataPSCreateFirstAccountsModel(data)
        }
        if(stateUpdate.accounts > 0){
            if(stateUpdate.ModeModel) dataPSCreateWithMultiaccountsModeModel(data)
            if(stateUpdate.Model) dataPSCreateWithMultiaccountsModel(data)
        }
    }

    const [deleteDialog, setDeleteDialog] = useState(false);
    const [preDeleteAccount, setPreDeleteAccount] = useState('');
    const handleOpenDeleteDialog = (name) => {
        setDeleteDialog(true);
        setPreDeleteAccount(name);
    }
    const handleCloseDeleteDialog = () => setDeleteDialog(false);
    const handleDeleteAccount = () => {
        if(stateUpdate.ModeModel){
            deleteAccountModeModel(preDeleteAccount)
        }

        if(stateUpdate.Model){
            deleteAccountModel(preDeleteAccount)
        }

        handleCloseDeleteDialog();
    }

    const deleteAccountModeModel = (name) => {
        setStateUpdate(prev => {
            let lockPrev = {
                ..._.cloneDeep(prev),
                ModeModel: [
                    ..._.cloneDeep(prev).ModeModel.filter(item => {
                        return item.account_id !== name;
                    })
                ]
            }
            if(lockPrev.ModeModel.length < 1){
                lockPrev = {
                    ...lockPrev,
                    ModeModel: [
                        {
                            account_id: "",
                            fields: paymentSystem.ModeModel[0].fields
                        }
                    ],
                    accounts: 0,
                }
                // Clear
                lockPrev.ModeModel.forEach(itemAcc => {
                    itemAcc.fields.forEach(item => {
                        if(item.field.type === 'checkbox'){
                            item.value = false
                        }else{
                            item.value = ''
                        }
                    })
                })
            }
            console.log('mm', lockPrev)
            return lockPrev
        });
        setAccounysTabsIndex(0);
    }

    const deleteAccountModel = (name) => {
        setStateUpdate(prev => {
            let lockPrev = {
                ..._.cloneDeep(prev),
                Model: [
                    ..._.cloneDeep(prev).Model.filter(item => {
                        return item.account_id !== name;
                    })
                ]
            }
            if(lockPrev.Model.length < 1){
                lockPrev = {
                    ...lockPrev,
                    Model: [
                        paymentSystem.Model[0]
                    ],
                    accounts: 0,
                }
                // Clear
                lockPrev.Model.forEach(itemAcc => {
                    for (let key in itemAcc.fields) {
                        itemAcc.fields[key].forEach(item => {
                            if(item.field.type === 'checkbox'){
                                item.value = false
                            }else{
                                item.value = ''
                            }
                        })
                    }
                })
            }
            return lockPrev
        });
        setAccounysTabsIndex(0);
    }

    // Set Dates for send

    const dataPSSaveWithModeModelAccounts = () => {
        const data = {
            [stateUpdate.modelName]: {},
            UserPaymentSystem: {
                active: stateUpdate.model.active ? 1 : 0,
                currencies: stateUpdate.currencies.filter(item => item.checked).map(item => item.field),
            },
        };
        stateUpdate.ModeModel.forEach(item => {
            const fieldUpdatedObj = {}
            item.fields.forEach(itemField => {
                if(itemField.field.type === 'checkbox'){
                    fieldUpdatedObj[itemField.attribute] = itemField.value ? 1 : 0;
                }else{
                    fieldUpdatedObj[itemField.attribute] = itemField.value;
                }
            })
            if(item.account_id === null || item.account_id === ""){
                data[stateUpdate.modelName]['-'] = fieldUpdatedObj;
            }else{
                data[stateUpdate.modelName][item.account_id] = fieldUpdatedObj;
            }
        })
        return data;
    }

    const dataPSSaveWithModelAccounts = () => {
        const data = {
            [stateUpdate.modelName]: {},
            UserPaymentSystem: {
                active: stateUpdate.model.active ? 1 : 0,
                currencies: stateUpdate.currencies.filter(item => item.checked).map(item => item.field),
            },
        };
        stateUpdate.Model.forEach(item => {
            const fieldUpdatedObj = {};
            for (let key in item.fields){
                const listFieldsData = {}
                item.fields[key].forEach(item => {
                    let valueInput: any = "";
                    if(item.field.type === 'checkbox'){
                        valueInput = item.value ? 1 : 0;
                    }else{
                        valueInput = item.value;
                    }
                    listFieldsData[item.attribute] = valueInput;
                })
                fieldUpdatedObj[key] = listFieldsData;
            }
            if(item.account_id === null || item.account_id === ""){
                data[stateUpdate.modelName]['-'] = fieldUpdatedObj;
            }else{
                data[stateUpdate.modelName][item.account_id] = fieldUpdatedObj;
            }
        })
        return data;
    }

    const dataPSSaveWithModeModel = () => {
        const data = {
            [stateUpdate.modelName]: {},
            UserPaymentSystem: {
                active: stateUpdate.model.active ? 1 : 0,
                currencies: stateUpdate.currencies.filter(item => item.checked).map(item => item.field),
            },
        };
        const fieldUpdatedObj = {}
        stateUpdate.ModeModel[0].fields.forEach(item => {
            if(item.field.type === 'checkbox'){
                fieldUpdatedObj[item.attribute] = item.value ? 1 : 0;
            }else{
                fieldUpdatedObj[item.attribute] = item.value;
            }
        })
        data[stateUpdate.modelName] = fieldUpdatedObj;
        return data;
    }

    const dataPSSaveWithModel = () => {
        const data = {
            [stateUpdate.modelName]: {},
            UserPaymentSystem: {
                active: stateUpdate.model.active ? 1 : 0,
                currencies: stateUpdate.currencies.filter(item => item.checked).map(item => item.field),
            },
        };
        stateUpdate.Model.forEach(item => {
            const fieldUpdatedObj = {};
            for (let key in item.fields){
                const listFieldsData = {}
                item.fields[key].forEach(item => {
                    let valueInput: any = "";
                    if(item.field.type === 'checkbox'){
                        valueInput = item.value ? 1 : 0;
                    }else{
                        valueInput = item.value;
                    }
                    listFieldsData[item.attribute] = valueInput;
                })
                fieldUpdatedObj[key] = listFieldsData;
            }
            data[stateUpdate.modelName] = fieldUpdatedObj;
        })
        return data;
    }

    const validateFields = () => {
        let hasError = false;
    
        // Проверка ModeModel (для dataPSSaveWithModeModel и dataPSSaveWithModeModelAccounts)
        if (stateUpdate.ModeModel) {
            stateUpdate.ModeModel.forEach(item => {
                item.fields.forEach(field => {
                    if (!field.value || field.value.toString().trim() === "") {
                        hasError = true;
                    }
                });
            });
        }
    
        // Проверка Model (для dataPSSaveWithModel и dataPSSaveWithModelAccounts)
        if (stateUpdate.Model) {
            stateUpdate.Model.forEach(item => {
                for (let key in item.fields) {
                    item.fields[key].forEach(field => {
                        if (!field.value || field.value.toString().trim() === "") {
                            hasError = true;
                        }
                    });
                }
            });
        }
    
        return hasError;
    };

    const hqndleSave = () => {
        if (validateFields()) {
            return;
        }

        if(stateUpdate.hasOwnProperty('accounts') && stateUpdate.accounts > 0){
            if(stateUpdate.ModeModel){
                // console.log('ModeModel SAVE', dataPSSaveWithModeModelAccounts());
                updateUserPaymentSystemWithAccounts(id, dataPSSaveWithModeModelAccounts())
                handleBack();
            }
            
            if(stateUpdate.Model){
                // console.log('Model SAVE', dataPSSaveWithModelAccounts());
                updateUserPaymentSystemWithAccounts(id, dataPSSaveWithModelAccounts())
                handleBack();
            }
        }else{
            if(stateUpdate.ModeModel){
                // console.log('SAVE FLAT ModeModel', dataPSSaveWithModeModel())
                updateUserPaymentSystemWithAccounts(id, dataPSSaveWithModeModel())
                handleBack();
            }
            
            if(stateUpdate.Model){
                // console.log('SAVE FLAT Model', dataPSSaveWithModel());
                updateUserPaymentSystemWithAccounts(id, dataPSSaveWithModel())
                handleBack();
            }
        }
    }

    const hqndleSaveAndStay = () => {
        if (validateFields()) {
            return;
        }
        
        if(stateUpdate.hasOwnProperty('accounts') && stateUpdate.accounts > 0){
            if(stateUpdate.ModeModel){
                updateUserPaymentSystemWithAccounts(id, dataPSSaveWithModeModelAccounts());
            }
            
            if(stateUpdate.Model){
                updateUserPaymentSystemWithAccounts(id, dataPSSaveWithModelAccounts());
            }
        }else{
            if(stateUpdate.ModeModel){
                updateUserPaymentSystemWithAccounts(id, dataPSSaveWithModeModel());
            }
            
            if(stateUpdate.Model){
                updateUserPaymentSystemWithAccounts(id, dataPSSaveWithModel());
            }
        }
    }

    console.log('Wtch State: ', stateUpdate)
    const keyString = ENV_CRTPTOKEY;
    const ivHex = ENV_IVHEX;
    // decriptCryptoJSstring(publicKey, keyString, ivHex)

    return (
        <PageContainer>

            {!accessedRoleView && <AccessDeniedAlert/>}

            {accessedRoleView && 
                <>
                    <ShortViewHeader>
                        <ShortViewHeaderLeft>
                            <HeaderLeftContainer>
                                <ArrowContainer>
                                    <ArrowBackSvg size="22px" onClick={() => handleBack()}/>
                                </ArrowContainer>
                                <ShortViewHeaderText>
                                    {error?.response?.status === 404 ? 'No data' : `Configure: ${paymentSystem?.model?.name}`}
                                </ShortViewHeaderText>
                            </HeaderLeftContainer>
                            { !(error?.response?.status === 404) && <Structure/> }
                        </ShortViewHeaderLeft>

                        { !(error?.response?.status === 404) &&
                            <ShortViewHeaderRight>
                                <Button
                                    onClick={hqndleSave}

                                    disabled={roleForDisableChange}
                                >
                                    save
                                </Button>
                                <Button 
                                    variant="outlined"
                                    onClick={hqndleSaveAndStay}

                                    disabled={roleForDisableChange}
                                >
                                    Update and stay
                                </Button>
                                <Button variant="outlined" onClick={() => handleBack()}>
                                    CANCEL
                                </Button>
                            </ShortViewHeaderRight>
                        }

                    </ShortViewHeader>
                    
                    { !(error?.response?.status === 404) &&
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                                <TabContext value={tabValue}>

                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <StyledTabs value={tabValue} onChange={handleChangeTab} aria-label="lab API tabs example">
                                            <StyledTab label="INFO" value="1" />
                                            <StyledTab label="methods" value="2" />
                                        </StyledTabs>
                                    </Box>

                                    <StyledTabPanel value="1">
                                        <WrapConfigureFormContainer>
                                            <WrapFields>     

                                                {/* Part with multiaccounts */}
                                                {stateUpdate.hasOwnProperty('accounts') && stateUpdate.accounts > 0
                                                    ? <>
                                                        <WrapTabsAccounts>
                                                            <WrapTabsList>
                                                                {stateUpdate.ModeModel &&
                                                                    stateUpdate.ModeModel.map((item, index) => {
                                                                        return <AccountTab 
                                                                            variant={`${index === accountsTaabIndex ? 'text' : 'outlined'}`}
                                                                            key={index}
                                                                            onClick={() => {
                                                                                handleClickAccountTab(index)
                                                                            }}
                                                                            >
                                                                            {item.account_id ? `Account ${item.account_id}` : 'Account-Null'}
                                                                        </AccountTab>
                                                                    })
                                                                }

                                                                {stateUpdate.Model &&
                                                                    stateUpdate.Model.map((item, index) => {
                                                                        return <AccountTab 
                                                                            variant={`${index === accountsTaabIndex ? 'text' : 'outlined'}`}
                                                                            key={index}
                                                                            onClick={() => {
                                                                                handleClickAccountTab(index)
                                                                            }}
                                                                            >
                                                                            {item.account_id ? `Account ${item.account_id}` : 'Account-Null'}
                                                                        </AccountTab>
                                                                    })
                                                                }
                                                            </WrapTabsList>
                                                            <WrapTabsButtonAdd>
                                                                <AccountAdd
                                                                    onClick={handleOpenCreatePopup}
                                                                    disabled={roleForDisableChange}
                                                                >
                                                                    <Tooltip title={`Add new account`} placement="top">
                                                                        <AddIcon/>
                                                                    </Tooltip>
                                                                </AccountAdd>
                                                            </WrapTabsButtonAdd>
                                                        </WrapTabsAccounts>
                                                    
                                                        {stateUpdate.ModeModel &&
                                                            <WrapDiv>
                                                                {
                                                                    stateUpdate.ModeModel.map((item, i) => {
                                                                        if(i === accountsTaabIndex){
                                                                            return <div key={i}>

                                                                                <WrapAccountTopSection>
                                                                                    <AccountTitle>{`Account-${item.account_id ? item.account_id : 'null'}`}</AccountTitle>
                                                                                    <AccountDelete
                                                                                        isDeleteButton  
                                                                                        size = "small"
                                                                                        onClick={() => handleOpenDeleteDialog(item.account_id)}
                                                                                        disabled={roleForDisableChange}
                                                                                    >
                                                                                        <Tooltip title={`Delete account-${item.account_id ? item.account_id : 'null'}`} placement="top">
                                                                                            <DeleteForeverIcon 
                                                                                                sx={{fontSize: '20px'}}
                                                                                            />
                                                                                        </Tooltip>
                                                                                    </AccountDelete>
                                                                                </WrapAccountTopSection>
                                                                                
                                                                                {
                                                                                    item.fields.map((itemField, itemId) => {
                                                                                        
                                                                                        if (itemField?.field?.type === 'text') {
                                                                                            return (
                                                                                                <Input 
                                                                                                    sx={{marginTop: '20px'}}
                                                                                                    label={`${itemField.field?.label}${itemField.field?.required ? ' *' : ''}`}
                                                                                                    value={
                                                                                                        roleForDisableChange 
                                                                                                            ? itemField?.value?.length > 4 
                                                                                                                ? itemField?.value?.substring(0, 4) + "·".repeat(itemField?.value?.length - 4) 
                                                                                                                : itemField.value 
                                                                                                            : itemField.value
                                                                                                    }
                                                                                                    disabled={roleForDisableChange}
                                                                                                    onChange={(e) => handleChangeStateUpdateAccountsModeModel(item.account_id, e, itemField)}
                                                                                                    key={itemId}
                                                                                                    // error={itemField?.field?.focused &&  !(itemField.field.required && itemField.value ? true : false)}
                                                                                                    error={itemField?.field?.focused ? itemField.field.required ? !Boolean(itemField.value) ? true : false : false : false}
                                                                                                    onBlur={() => handleChangeFocuseAccountsModeModel(item.account_id, itemField)}
                                                                                                />
                                                                                            )
                                                                                        } else if (itemField?.field.type === 'number') {
                                                                                            return (
                                                                                                <Input
                                                                                                    sx={{marginTop: '20px'}}
                                                                                                    label={`${itemField.field.label}${itemField.field.required ? ' *' : ''}`}
                                                                                                    value={itemField.value}
                                                                                                    disabled={roleForDisableChange}
                                                                                                    type="number"
                                                                                                    onChange={(e) => handleChangeStateUpdateAccountsModeModel(item.account_id, e, itemField)}
                                                                                                    key={itemId}
                                                                                                    // error={itemField?.field?.focused &&  !(itemField.field.required && itemField.value ? true : false)}
                                                                                                    error={itemField?.field?.focused ? itemField.field.required ? !Boolean(itemField.value) ? true : false : false : false}
                                                                                                    onBlur={() => handleChangeFocuseAccountsModeModel(item.account_id, itemField)}
                                                                                                />
                                                                                            );
                                                                                        } else if (itemField?.field.type === 'password') {
                                                                                            return (
                                                                                                <Input
                                                                                                    sx={{marginTop: '20px'}}
                                                                                                    label={`${itemField.field.label}${itemField.field.required ? ' *' : ''}`}
                                                                                                    value={
                                                                                                        roleForDisableChange ? itemField?.value?.length > 4 ? itemField?.value?.substring(0, 4) + "·".repeat(itemField?.value?.length - 4) : itemField.value : itemField.value
                                                                                                    }
                                                                                                    disabled={roleForDisableChange}
                                                                                                    type="text"
                                                                                                    onChange={(e) => handleChangeStateUpdateAccountsModeModel(item.account_id, e, itemField)}
                                                                                                    key={itemId}
                                                                                                    // error={itemField?.field?.focused &&  !(itemField.field.required && itemField.value ? true : false)}
                                                                                                    error={itemField?.field?.focused ? itemField.field.required ? !Boolean(itemField.value) ? true : false : false : false}
                                                                                                    onBlur={() => handleChangeFocuseAccountsModeModel(item.account_id, itemField)}
                                                                                                />
                                                                                            );
                                                                                        } else if (itemField?.field.type === 'select' || itemField?.field.type === 'list') {
                                                                                            return (
                                                                                                <StyledFormControl fullWidth key={itemId}>
                                                                                                    <InputLabel size="small">{`${itemField.field.label}${itemField.field.required ? ' *' : ''}`}</InputLabel>
                                                                                                    <Select
                                                                                                        value={itemField.value}
                                                                                                        label={`${itemField.field.label}${itemField.field.required ? ' *' : ''}`}
                                                                                                        onChange={(e) => handleChangeStateUpdateAccountsModeModel(item.account_id, e, itemField)}
                                                                                                        size='small'
                                                                                                        key={itemId}
                                                                                                        disabled={roleForDisableChange}
                                                                                                        // error={itemField?.field?.focused && !(itemField.field.required && itemField.value ? true : false)}
                                                                                                        error={itemField?.field?.focused ? itemField.field.required ? !Boolean(itemField.value) ? true : false : false : false}
                                                                                                        onBlur={() => handleChangeFocuseAccountsModeModel(item.account_id, itemField)}
                                                                                                    >
                                                                                                        {Object.entries(itemField.field.values).map((i: any) => {
                                                                                                            return (
                                                                                                                <StyledMenuItem key={i} value={i[0]}>{i[1]}</StyledMenuItem>
                                                                                                            )
                                                                                                        })}
                                                                                                    </Select>
                                                                                                </StyledFormControl>
                                                                                            )
                                                                                        } else if (itemField?.field.type === 'checkbox') {
                                                                                            return (
                                                                                                <SwitchComponent 
                                                                                                    checked={Boolean(itemField.value)}
                                                                                                    onChange={(e) => handleChangeStateUpdateAccountsModeModel(item.account_id, e, itemField)}
                                                                                                    sx={{marginTop: '20px'}}
                                                                                                    label={`${itemField.field.label}${itemField.field.required ? ' *' : ''}`}
                                                                                                    key={itemId}
                                                                                                    disabled={roleForDisableChange}
                                                                                                />
                                                                                            );
                                                                                        } else if (itemField.field.type === 'text-area') {
                                                                                            return (
                                                                                                <Input
                                                                                                    sx={{marginTop: '20px'}}
                                                                                                    label={`${itemField.field.label}${itemField.field.required ? ' *' : ''}`}
                                                                                                    onChange={(e) => handleChangeStateUpdateAccountsModeModel(item.account_id, e, itemField)}
                                                                                                    value={
                                                                                                        roleForDisableChange ? itemField?.value?.length > 4 ? itemField?.value?.substring(0, 4) + "·".repeat(itemField?.value?.length - 4) : itemField.value : itemField.value
                                                                                                    }
                                                                                                    key={itemId}
                                                                                                    multiline
                                                                                                    rows={6}
                                                                                                    disabled={roleForDisableChange}
                                                                                                    // error={itemField?.field?.focused &&  !(itemField.field.required && itemField.value ? true : false)}
                                                                                                    error={itemField?.field?.focused ? itemField.field.required ? !Boolean(itemField.value) ? true : false : false : false}
                                                                                                    onBlur={() => handleChangeFocuseAccountsModeModel(item.account_id, itemField)}
                                                                                                />
                                                                                            )
                                                                                        } else if (itemField.field.type === 'merchant_id' || 'merchant_key' || 'merchant_secret') {
                                                                                            return (
                                                                                                <Input
                                                                                                    sx={{marginTop: '20px'}}
                                                                                                    label={`${itemField.field.label}${itemField.field.required ? ' *' : ''}`}
                                                                                                    onChange={(e) => handleChangeStateUpdateAccountsModeModel(item.account_id, e, itemField)}
                                                                                                    value={
                                                                                                        roleForDisableChange ? itemField?.value?.length > 4 ? itemField?.value?.substring(0, 4) + "·".repeat(itemField?.value?.length - 4) : itemField.value : itemField.value
                                                                                                    }
                                                                                                    disabled={roleForDisableChange}
                                                                                                    key={itemId}
                                                                                                    // error={itemField?.field?.focused &&  !(itemField.field.required && itemField.value ? true : false)}
                                                                                                    error={itemField?.field?.focused ? itemField.field.required ? !Boolean(itemField.value) ? true : false : false : false}
                                                                                                    onBlur={() => handleChangeFocuseAccountsModeModel(item.account_id, itemField)}
                                                                                                />
                                                                                            )
                                                                                        } else {
                                                                                            return <div key={itemId}>{itemField?.field.type} Unknown type field!</div>
                                                                                        }
                                                                                        
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        } 
                                                                        return <></>
                                                                    })
                                                                }
                                                            </WrapDiv>
                                                        }

                                                        {stateUpdate.Model &&
                                                            <div>
                                                                <WrapConfigureFormContainer>
                                                                    {
                                                                        stateUpdate.Model.map((itemAccount, i) => {
                                                                            if(i === accountsTaabIndex){
                                                                                return <div key={i}>
                                                                                
                                                                                    <WrapAccountTopSection>
                                                                                        <AccountTitle>{`Account-${itemAccount.account_id ? itemAccount.account_id : 'null'}`}</AccountTitle>
                                                                                        <AccountDelete
                                                                                            isDeleteButton  
                                                                                            size = "small"
                                                                                            onClick={() => handleOpenDeleteDialog(itemAccount.account_id)}
                                                                                            disabled={roleForDisableChange}
                                                                                        >
                                                                                            <Tooltip title={`Delete account-${itemAccount.account_id ? itemAccount.account_id : 'null'}`} placement="top">
                                                                                                <DeleteForeverIcon sx={{fontSize: '20px'}}/>
                                                                                            </Tooltip>
                                                                                        </AccountDelete>
                                                                                    </WrapAccountTopSection>

                                                                                    <WrapCurrencyTab>
                                                                                        {
                                                                                            Object.entries(itemAccount.fields).map((item, index) => {
                                                                                                // eslint-disable-next-line
                                                                                                if(item[0] === '0') return
                                                                                                return <CurrencyTab 
                                                                                                        variant={`${index === secondsTansIndex ? 'text' : 'outlined'}`}
                                                                                                        key={index}
                                                                                                        onClick={() => {
                                                                                                            handleClickCurrencyTab(index)
                                                                                                        }}
                                                                                                    >
                                                                                                    {item[0]}
                                                                                                </CurrencyTab>
                                                                                            })
                                                                                        }
                                                                                    </WrapCurrencyTab>

                                                                                    <div>
                                                                                        {
                                                                                            Object.entries(itemAccount.fields).map((item, i) => {
                                                                                                // eslint-disable-next-line
                                                                                                if(item[0] === '0') return
                                                                                                if(i === secondsTansIndex) return <TextTitleCurrency key={i}>{item[0]}</TextTitleCurrency>
                                                                                                return <></>
                                                                                            })
                                                                                        }
                                                                                    </div>

                                                                                    <div>
                                                                                        {itemAccount.fields &&
                                                                                            (Object.entries(itemAccount.fields).map((itemCurrency, i) => {
                                                                                                if(i === secondsTansIndex){
                                                                                                    return (itemCurrency[1] || [] as any).map((item, i) => {

                                                                                                        if (item?.field.type === 'text' ) {
                                                                                                            return <Input 
                                                                                                                sx={{marginTop: '20px'}}
                                                                                                                label={`${item.field?.label}${item.field?.required ? ' *' : ''}`}
                                                                                                                value={
                                                                                                                    roleForDisableChange 
                                                                                                                        ? item?.value?.length > 4 
                                                                                                                            ? item?.value?.substring(0, 4) + "·".repeat(item?.value?.length - 4) 
                                                                                                                            : item.value 
                                                                                                                        : item.value === null ? '' : item.value
                                                                                                                }
                                                                                                                disabled={roleForDisableChange}
                                                                                                                onChange={(e) => handleChangeStateUpdateAccountsModel(itemAccount.account_id, itemCurrency[0], item, e)}
                                                                                                                key={i}
                                                                                                                // error={item?.field?.focused &&  !(item.field.required && item.value ? true : false)}
                                                                                                                error={item?.field?.focused ? item.field.required ? !Boolean(item.value) ? true : false : false : false}
                                                                                                                onBlur={() => handleChangeFocuseAccountsModel(itemAccount.account_id, itemCurrency[0], item)}
                                                                                                            />
                                                                                                        } else if (item?.field.type === 'password') {
                                                                                                            return (
                                                                                                                <Input
                                                                                                                    sx={{marginTop: '20px'}}
                                                                                                                    label={`${item.field.label}${item.field.required ? ' *' : ''}`}
                                                                                                                    value={
                                                                                                                        roleForDisableChange 
                                                                                                                            ? item?.value?.length > 4 
                                                                                                                                ? item?.value?.substring(0, 4) + "·".repeat(item?.value?.length - 4) 
                                                                                                                                : item.value 
                                                                                                                            :  item.value === null ? '' : item.value
                                                                                                                    }
                                                                                                                    disabled={roleForDisableChange}
                                                                                                                    type="text"
                                                                                                                    onChange={(e) => handleChangeStateUpdateAccountsModel(itemAccount.account_id, itemCurrency[0], item, e)}
                                                                                                                    key={i}
                                                                                                                    // error={item?.field?.focused &&  !(item.field.required && item.value ? true : false)}
                                                                                                                    error={item?.field?.focused ? item.field.required ? !Boolean(item.value) ? true : false : false : false}
                                                                                                                    onBlur={() => handleChangeFocuseAccountsModel(itemAccount.account_id, itemCurrency[0], item)}
                                                                                                                />
                                                                                                            );
                                                                                                        } else if (item?.field.type === 'number') {
                                                                                                            return (
                                                                                                                <Input
                                                                                                                    sx={{marginTop: '20px'}}
                                                                                                                    label={`${item.field.label}${item.field.required ? ' *' : ''}`}
                                                                                                                    value={item.value}
                                                                                                                    type="number"
                                                                                                                    onChange={(e) => handleChangeStateUpdateAccountsModel(itemAccount.account_id, itemCurrency[0], item, e)}
                                                                                                                    key={i}
                                                                                                                    disabled={roleForDisableChange}
                                                                                                                    // error={item?.field?.focused &&  !(item.field.required && item.value ? true : false)}
                                                                                                                    error={item?.field?.focused ? item.field.required ? !Boolean(item.value) ? true : false : false : false}
                                                                                                                    onBlur={() => handleChangeFocuseAccountsModel(itemAccount.account_id, itemCurrency[0], item)}
                                                                                                                />
                                                                                                            );
                                                                                                        } else if (item?.field.type === 'select' || item?.field.type === 'list') {
                                                                                                            return (
                                                                                                                <StyledFormControl fullWidth key={id}>
                                                                                                                    <InputLabel size="small">{`${item.field.label}${item.field.required ? ' *' : ''}`}</InputLabel>
                                                                                                                    <Select
                                                                                                                        value={item.value}
                                                                                                                        label={`${item.field.label}${item.field.required ? ' *' : ''}`}
                                                                                                                        onChange={(e) => handleChangeStateUpdateAccountsModel(itemAccount.account_id, itemCurrency[0], item, e)}
                                                                                                                        size='small'
                                                                                                                        key={i}
                                                                                                                        disabled={roleForDisableChange}
                                                                                                                        // error={item?.field?.focused && !(item.field.required && item.value ? true : false)}
                                                                                                                        error={item?.field?.focused ? item.field.required ? !Boolean(item.value) ? true : false : false : false}
                                                                                                                        onBlur={() => handleChangeFocuseAccountsModel(itemAccount.account_id, itemCurrency[0], item)}
                                                                                                                    >
                                                                                                                        {Object.entries(item.field.values).map((i: any) => {
                                                                                                                            return (
                                                                                                                                <StyledMenuItem key={i} value={i[0]}>{i[1]}</StyledMenuItem>
                                                                                                                            )
                                                                                                                        })}
                                                                                                                    </Select>
                                                                                                                </StyledFormControl>
                                                                                                            )
                                                                                                        } else if (item?.field.type === 'checkbox') {
                                                                                                            return (
                                                                                                                <SwitchComponent 
                                                                                                                    checked={Boolean(item.value)}
                                                                                                                    onChange={(e) => handleChangeStateUpdateAccountsModel(itemAccount.account_id, itemCurrency[0], item, e)}
                                                                                                                    sx={{marginTop: '20px'}}
                                                                                                                    label={`${item.field.label}${item.field.required ? ' *' : ''}`}
                                                                                                                    key={i}
                                                                                                                    disabled={roleForDisableChange}
                                                                                                                />
                                                                                                            );
                                                                                                        } else if (item.field.type === 'text-area') {
                                                                                                            return (
                                                                                                                <Input
                                                                                                                    sx={{marginTop: '20px'}}
                                                                                                                    label={`${item.field.label}${item.field.required ? ' *' : ''}`}
                                                                                                                    onChange={(e) => handleChangeStateUpdateAccountsModel(itemAccount.account_id, itemCurrency[0], item, e)}
                                                                                                                    value={
                                                                                                                        roleForDisableChange ? item?.value?.length > 4 ? item?.value?.substring(0, 4) + "·".repeat(item?.value?.length - 4) : item.value : item.value
                                                                                                                    }
                                                                                                                    key={i}
                                                                                                                    multiline
                                                                                                                    rows={6}
                                                                                                                    disabled={roleForDisableChange}
                                                                                                                    // error={item?.field?.focused &&  !(item.field.required && item.value ? true : false)}
                                                                                                                    error={item?.field?.focused ? item.field.required ? !Boolean(item.value) ? true : false : false : false}
                                                                                                                    onBlur={() => handleChangeFocuseAccountsModel(itemAccount.account_id, itemCurrency[0], item)}
                                                                                                                />
                                                                                                            )
                                                                                                        } else if (item.field.type === 'merchant_id' || 'merchant_key' || 'merchant_secret') {
                                                                                                            return (
                                                                                                                <Input
                                                                                                                    sx={{marginTop: '20px'}}
                                                                                                                    label={`${item.field.label}${item.field.required ? ' *' : ''}`}
                                                                                                                    onChange={(e) => handleChangeStateUpdateAccountsModel(itemAccount.account_id, itemCurrency[0], item, e)}
                                                                                                                    value={
                                                                                                                        roleForDisableChange 
                                                                                                                            ? item?.value?.length > 4 
                                                                                                                                ? item?.value?.substring(0, 4) + "·".repeat(item?.value?.length - 4) 
                                                                                                                                : item.value 
                                                                                                                            : item.value === null ? '' : item.value
                                                                                                                    }
                                                                                                                    disabled={roleForDisableChange}
                                                                                                                    key={i}
                                                                                                                    // error={item?.field?.focused &&  !(item.field.required && item.value ? true : false)}
                                                                                                                    error={item?.field?.focused ? item.field.required ? !Boolean(item.value) ? true : false : false : false}
                                                                                                                    onBlur={() => handleChangeFocuseAccountsModel(itemAccount.account_id, itemCurrency[0], item)}
                                                                                                                />
                                                                                                            )
                                                                                                        } else {
                                                                                                            return <div key={i}>{item?.field.type} !!!!!!!!!!!!!!!!!!</div>
                                                                                                        }
                                                                                                        
                                                                                                    })
                                                                                                } else {
                                                                                                    // eslint-disable-next-line
                                                                                                    return;
                                                                                                }
                                                                                            }))
                                                                                        }
                                                                                    </div>
                                                                                
                                                                                </div>
                                                                            }
                                                                            return <></>
                                                                        })
                                                                    }
                                                                </WrapConfigureFormContainer>
                                                            </div>
                                                        }
                                                        
                                                        
                                                        {/* Active switcher */}
                                                        <div>
                                                            {stateUpdate.model.hasOwnProperty('active') && 
                                                                <SwitchComponent 
                                                                    checked={Boolean(stateUpdate.model.active)}
                                                                    onChange={(e) => handleChangeStateUpdateActivePS(e)}
                                                                    sx={{marginTop: '20px'}}
                                                                    label={`Active *`}
                                                                    disabled={roleForDisableChange}
                                                                />
                                                            }
                                                        </div>
                                                    </>
                                                    : <>
                                                        {/* No multiaccounts data */}

                                                        <WrapTabsAccounts style={{marginBottom: '20px'}}>
                                                            <WrapTabsButtonAdd>
                                                                <AccountAdd
                                                                    onClick={handleOpenCreatePopup}
                                                                    disabled={roleForDisableChange}
                                                                >
                                                                    <Tooltip title={`Add new account`} placement="top">
                                                                        <AddIcon/>
                                                                    </Tooltip>
                                                                </AccountAdd>
                                                            </WrapTabsButtonAdd>
                                                        </WrapTabsAccounts>

                                                        { stateUpdate.ModeModel &&
                                                            <>
                                                                {
                                                                    // eslint-disable-next-line
                                                                    (stateUpdate?.ModeModel || []).map((itemMM: any, idItem: any) => { 
                                                                        return itemMM.fields.map((item, i) => {
                                                                            if (item?.field.type === 'text') {
                                                                                return <Input 
                                                                                    sx={{marginTop: '20px'}}
                                                                                    label={`${item.field?.label}${item.field?.required ? ' *' : ''}`}
                                                                                    value={
                                                                                        roleForDisableChange ? item?.value?.length > 4 ? item?.value?.substring(0, 4) + "·".repeat(item?.value?.length - 4) : item.value : item.value
                                                                                    }
                                                                                    disabled={roleForDisableChange}
                                                                                    onChange={(e) => handleChangePaymentSystemCredentials(item, e)}
                                                                                    key={i}
                                                                                    // error={item?.field?.focused && !(item.field.required && item.value ? true : false)}
                                                                                    error={item?.field?.focused ? item.field.required ? !Boolean(item.value) ? true : false : false : false}
                                                                                    onBlur={() => handleChangeFocuseModeModel(item)}
                                                                                />
                                                                            
                                                                            } else if (item?.field.type === 'password') {
                                                                                return (
                                                                                    <Input
                                                                                        sx={{marginTop: '20px'}}
                                                                                        label={`${item.field.label}${item.field.required ? ' *' : ''}`}
                                                                                        value={
                                                                                            roleForDisableChange ? item?.value?.length > 4 ? item?.value?.substring(0, 4) + "·".repeat(item?.value?.length - 4) : item.value : item.value
                                                                                        }
                                                                                        disabled={roleForDisableChange}
                                                                                        type="text"
                                                                                        onChange={(e) => handleChangePaymentSystemCredentials(item, e)}
                                                                                        key={i}
                                                                                        // error={item?.field?.focused && !(item.field.required && item.value ? true : false)}
                                                                                        error={item?.field?.focused ? item.field.required ? !Boolean(item.value) ? true : false : false : false}
                                                                                        onBlur={() => handleChangeFocuseModeModel(item)}
                                                                                    />
                                                                                );
                                                                            } else if (item?.field.type === 'number') {
                                                                                return (
                                                                                    <Input
                                                                                        sx={{marginTop: '20px'}}
                                                                                        label={`${item.field.label}${item.field.required ? ' *' : ''}`}
                                                                                        value={item.value}
                                                                                        disabled={roleForDisableChange}
                                                                                        type="number"
                                                                                        onChange={(e) => handleChangePaymentSystemCredentials(item, e)}
                                                                                        key={i}
                                                                                        // error={item?.field?.focused && !(item.field.required && item.value ? true : false)}
                                                                                        error={item?.field?.focused ? item.field.required ? !Boolean(item.value) ? true : false : false : false}
                                                                                        onBlur={() => handleChangeFocuseModeModel(item)}
                                                                                    />
                                                                                );
                                                                            } else if (item?.field.type === 'select' || item?.field.type === 'list') {
                                                                                return (
                                                                                    <StyledFormControl fullWidth key={idItem}>
                                                                                        <InputLabel size="small">{`${item.field.label}${item.field.required ? ' *' : ''}`}</InputLabel>
                                                                                        <Select
                                                                                            value={item.value}
                                                                                            label={`${item.field.label}${item.field.required ? ' *' : ''}`}
                                                                                            onChange={(e) => handleChangePaymentSystemCredentials(item, e)}
                                                                                            size='small'
                                                                                            key={i}
                                                                                            disabled={roleForDisableChange}
                                                                                            // error={item?.field?.focused && !(item.field.required && item.value ? true : false)}
                                                                                            error={item?.field?.focused ? item.field.required ? !Boolean(item.value) ? true : false : false : false}
                                                                                            onBlur={() => handleChangeFocuseModeModel(item)}
                                                                                        >
                                                                                            {Object.entries(item.field.values).map((i: any) => {
                                                                                                return (
                                                                                                    <StyledMenuItem key={i} value={i[0]}>{i[1]}</StyledMenuItem>
                                                                                                )
                                                                                            })}
                                                                                        </Select>
                                                                                    </StyledFormControl>
                                                                                )
                                                                            } else if (item?.field.type === 'checkbox') {
                                                                                return (
                                                                                    <SwitchComponent 
                                                                                        checked={Boolean(item.value)}
                                                                                        onChange={(e) => handleChangePaymentSystemCredentials(item, e)}
                                                                                        sx={{marginTop: '20px'}}
                                                                                        label={`${item.field.label}${item.field.required ? ' *' : ''}`}
                                                                                        key={i}
                                                                                        disabled={roleForDisableChange}
                                                                                    />
                                                                                );
                                                                            } else if (item.field.type === 'text-area') {
                                                                                return (
                                                                                    <Input
                                                                                        sx={{marginTop: '20px'}}
                                                                                        label={`${item.field.label}${item.field.required ? ' *' : ''}`}
                                                                                        onChange={(e) => handleChangePaymentSystemCredentials(item, e)}
                                                                                        value={
                                                                                            roleForDisableChange ? item?.value?.length > 4 ? item?.value?.substring(0, 4) + "·".repeat(item?.value?.length - 4) : item.value : item.value
                                                                                        }
                                                                                        key={i}
                                                                                        multiline
                                                                                        rows={6}
                                                                                        disabled={roleForDisableChange}
                                                                                        // error={item?.field?.focused && !(item.field.required && item.value ? true : false)}
                                                                                        error={item?.field?.focused ? item.field.required ? !Boolean(item.value) ? true : false : false : false}
                                                                                        onBlur={() => handleChangeFocuseModeModel(item)}
                                                                                    />
                                                                                )
                                                                            } else if (item.field.type === 'merchant_id' || 'merchant_key' || 'merchant_secret') {
                                                                                return (
                                                                                    <Input
                                                                                        sx={{marginTop: '20px'}}
                                                                                        label={`${item.field.label}${item.field.required ? ' *' : ''}`}
                                                                                        onChange={(e) => handleChangePaymentSystemCredentials(item, e)}
                                                                                        value={
                                                                                            roleForDisableChange ? item?.value?.length > 4 ? item?.value?.substring(0, 4) + "·".repeat(item?.value?.length - 4) : item.value : item.value
                                                                                        }
                                                                                        disabled={roleForDisableChange}
                                                                                        key={i}
                                                                                        // error={item?.field?.focused && !(item.field.required && item.value ? true : false)}
                                                                                        error={item?.field?.focused ? item.field.required ? !Boolean(item.value) ? true : false : false : false}
                                                                                        onBlur={() => handleChangeFocuseModeModel(item)}
                                                                                    />
                                                                                )
                                                                            } else {
                                                                                return <div key={idItem}>{item?.field.type} !!!!!!!!!!!!!!!!!!</div>
                                                                            }
                                                                        })
                                                                    })
                                                                }
                                                            </>
                                                        }

                                                        { stateUpdate.Model &&
                                                            <>
                                                                <WrapCurrencyTab>
                                                                    {
                                                                        Object.entries(stateUpdate.Model[0].fields || {}).map((item, i) => {
                                                                            // eslint-disable-next-line
                                                                            if(item[0] === '0') return
                                                                            return <CurrencyTab 
                                                                                variant={`${i === secondsTansIndex ? 'text' : 'outlined'}`}
                                                                                key={i}
                                                                                onClick={() => {
                                                                                    handleClickCurrencyTab(i)
                                                                                }}
                                                                                >
                                                                                {item[0]}
                                                                            </CurrencyTab>
                                                                        })
                                                                    }
                                                                </WrapCurrencyTab>
                                                                <WrapConfigureFormContainer>
                                                                    <>
                                                                        {
                                                                            (Object.entries(stateUpdate?.Model[0]?.fields || {}).map((itemCurrency, i) => {
                                                                                if(i === secondsTansIndex){
                                                                                    return (itemCurrency[1] || [] as any).map((item, i) => {
                                                                                        
                                                                                        if (item?.field.type === 'text' ) {
                                                                                            return <Input 
                                                                                                sx={{marginTop: '20px'}}
                                                                                                label={`${item.field?.label}${item.field?.required ? ' *' : ''}`}
                                                                                                value={
                                                                                                    roleForDisableChange ? item?.value?.length > 4 ? item?.value?.substring(0, 4) + "·".repeat(item?.value?.length - 4) : item.value : item.value
                                                                                                }
                                                                                                disabled={roleForDisableChange}
                                                                                                onChange={(e) => handleChangePaymentSystemCredentialsModel(itemCurrency[0], item, e)}
                                                                                                key={i}
                                                                                                // error={item?.field?.focused &&  !(item.field.required && item.value ? true : false)}
                                                                                                error={item?.field?.focused ? item.field.required ? !Boolean(item.value) ? true : false : false : false}
                                                                                                onBlur={() => handleChangeFocuseModel(itemCurrency[0], item)}
                                                                                            />
                                                                                        } else if (item?.field.type === 'password') {
                                                                                            return (
                                                                                                <Input
                                                                                                    sx={{marginTop: '20px'}}
                                                                                                    label={`${item.field.label}${item.field.required ? ' *' : ''}`}
                                                                                                    value={
                                                                                                        roleForDisableChange ? item?.value?.length > 4 ? item?.value?.substring(0, 4) + "·".repeat(item?.value?.length - 4) : item.value : item.value
                                                                                                    }
                                                                                                    disabled={roleForDisableChange}
                                                                                                    type="text"
                                                                                                    onChange={(e) => handleChangePaymentSystemCredentialsModel(itemCurrency[0], item, e)}
                                                                                                    key={i}
                                                                                                    // error={item?.field?.focused &&  !(item.field.required && item.value ? true : false)}
                                                                                                    error={item?.field?.focused ? item.field.required ? !Boolean(item.value) ? true : false : false : false}
                                                                                                    onBlur={() => handleChangeFocuseModel(itemCurrency[0], item)}
                                                                                                />
                                                                                            );
                                                                                        } else if (item?.field.type === 'number') {
                                                                                            return (
                                                                                                <Input
                                                                                                    sx={{marginTop: '20px'}}
                                                                                                    label={`${item.field.label}${item.field.required ? ' *' : ''}`}
                                                                                                    value={item.value === null ? '' : item.value}
                                                                                                    type="number"
                                                                                                    onChange={(e) => handleChangePaymentSystemCredentialsModel(itemCurrency[0], item, e)}
                                                                                                    key={i}
                                                                                                    disabled={roleForDisableChange}
                                                                                                    // error={item?.field?.focused && !(item.field.required && item.value ? true : false)}
                                                                                                    error={item?.field?.focused ? item.field.required ? !Boolean(item.value) ? true : false : false : false}
                                                                                                    onBlur={() => handleChangeFocuseModel(itemCurrency[0], item)}
                                                                                                />
                                                                                            );
                                                                                        } else if (item?.field.type === 'select' || item?.field.type === 'list') {
                                                                                            return (
                                                                                                <StyledFormControl fullWidth key={id}>
                                                                                                    <InputLabel size="small">{`${item.field.label}${item.field.required ? ' *' : ''}`}</InputLabel>
                                                                                                    <Select
                                                                                                        value={item.value}
                                                                                                        label={`${item.field.label}${item.field.required ? ' *' : ''}`}
                                                                                                        onChange={(e) => handleChangePaymentSystemCredentialsModel(itemCurrency[0], item, e)}
                                                                                                        size='small'
                                                                                                        key={i}
                                                                                                        disabled={roleForDisableChange}
                                                                                                        // error={item?.field?.focused && !(item.field.required && item.value ? true : false)}
                                                                                                        error={item?.field?.focused ? item.field.required ? !Boolean(item.value) ? true : false : false : false}
                                                                                                        onBlur={() => handleChangeFocuseModel(itemCurrency[0], item)}
                                                                                                    >
                                                                                                        {Object.entries(item.field.values).map((i: any) => {
                                                                                                            return (
                                                                                                                <StyledMenuItem key={i} value={i[0]}>{i[1]}</StyledMenuItem>
                                                                                                            )
                                                                                                        })}
                                                                                                    </Select>
                                                                                                </StyledFormControl>
                                                                                            )
                                                                                        } else if (item?.field.type === 'checkbox') {
                                                                                            return (
                                                                                                <SwitchComponent 
                                                                                                    checked={Boolean(item.value)}
                                                                                                    onChange={(e) => handleChangePaymentSystemCredentialsModel(itemCurrency[0], item, e)}
                                                                                                    sx={{marginTop: '20px'}}
                                                                                                    label={`${item.field.label}${item.field.required ? ' *' : ''}`}
                                                                                                    key={i}
                                                                                                    disabled={roleForDisableChange}
                                                                                                />
                                                                                            );
                                                                                        } else if (item.field.type === 'text-area') {
                                                                                            return (
                                                                                                <Input
                                                                                                    sx={{marginTop: '20px'}}
                                                                                                    label={`${item.field.label}${item.field.required ? ' *' : ''}`}
                                                                                                    onChange={(e) => handleChangePaymentSystemCredentialsModel(itemCurrency[0], item, e)}
                                                                                                    value={
                                                                                                        roleForDisableChange ? item?.value?.length > 4 ? item?.value?.substring(0, 4) + "·".repeat(item?.value?.length - 4) : item.value : item.value
                                                                                                    }
                                                                                                    key={i}
                                                                                                    multiline
                                                                                                    rows={6}
                                                                                                    disabled={roleForDisableChange}
                                                                                                    // error={item?.field?.focused && !(item.field.required && item.value ? true : false)}
                                                                                                    error={item?.field?.focused ? item.field.required ? !Boolean(item.value) ? true : false : false : false}
                                                                                                    onBlur={() => handleChangeFocuseModel(itemCurrency[0], item)}
                                                                                                />
                                                                                            )
                                                                                        } else if (item.field.type === 'merchant_id' || 'merchant_key' || 'merchant_secret') {
                                                                                            return (
                                                                                                <Input
                                                                                                    sx={{marginTop: '20px'}}
                                                                                                    label={`${item.field.label}${item.field.required ? ' *' : ''}`}
                                                                                                    onChange={(e) => handleChangePaymentSystemCredentialsModel(itemCurrency[0], item, e)}
                                                                                                    value={
                                                                                                        roleForDisableChange ? item?.value?.length > 4 ? item?.value?.substring(0, 4) + "·".repeat(item?.value?.length - 4) : item.value : item.value
                                                                                                    }
                                                                                                    disabled={roleForDisableChange}
                                                                                                    key={i}
                                                                                                    // error={item?.field?.focused && !(item.field.required && item.value ? true : false)}
                                                                                                    error={item?.field?.focused ? item.field.required ? !Boolean(item.value) ? true : false : false : false}
                                                                                                    onBlur={() => handleChangeFocuseModel(itemCurrency[0], item)}
                                                                                                />
                                                                                            )
                                                                                        } else {
                                                                                            return <div key={i}>{item?.field.type} !Unknown type field</div>
                                                                                        }

                                                                                    })
                                                                                } else {
                                                                                    // eslint-disable-next-line
                                                                                    return;
                                                                                }
                                                                            }))
                                                                        }
                                                                    </>
                                                                </WrapConfigureFormContainer>
                                                            </>
                                                        }

                                                        {/* Active switcher */}
                                                        <div>
                                                            {stateUpdate.model.hasOwnProperty('active') && 
                                                                <SwitchComponent 
                                                                    checked={Boolean(stateUpdate.model.active)}
                                                                    onChange={(e) => handleChangeStateUpdateActivePS(e)}
                                                                    sx={{marginTop: '20px'}}
                                                                    label={`Active *`}
                                                                    disabled={roleForDisableChange}
                                                                />
                                                            }
                                                        </div>
                                                    </>
                                                } 

                                            </WrapFields>
                                        </WrapConfigureFormContainer>
                                    </StyledTabPanel>

                                    <StyledTabPanel value="2">

                                        <TopSortedButton>
                                            <ButtonText 
                                                variant="outlined"
                                                onClick={handleHideEnabledMethods}
                                            >
                                                Show only selected
                                            </ButtonText>
                                            <ButtonText 
                                                variant="outlined" 
                                                onClick={handleHideEnabledMethodsClear}
                                                disabled={isClearEnabled}
                                            > 
                                                clear
                                            </ButtonText>
                                        </TopSortedButton>

                                        <StyledPaperTable>
                                            <TableContainer component={'div'}>
                                                <Table>
                                                    <StyledTableHead>
                                                        <TableRow>
                                                            <TableCell padding="checkbox" sx={{width: "40px"}}>
                                                                <StyledCheckbox 
                                                                    checked={sortedMethodsCurrency(stateUpdate.currencies).every((item) => item.isAllChecked)}
                                                                    onClick={(e) =>  handleClickMainFilterCurency(e)}
                                                                    disabled={roleForDisableChange}
                                                                />
                                                            </TableCell>

                                                            <TableCell sx={{width: "184px"}}>Currencies</TableCell>

                                                            <WithBorderLeftTableCell sx={{width: "calc(100% - 184px - 40px)", paddingLeft: '0px'}}>
                                                                <Table sx={{height: "100%"}}>
                                                                    <TableBody>
                                                                        <TableRow sx={{width: "100%"}}>
                                                                            <StyledThTableCell sx={{minWidth: '84px', width: "84px",}}>Active</StyledThTableCell>
                                                                            <StyledThTableCell sx={{minWidth: '190px',width: "190px",}}>Payment method</StyledThTableCell>
                                                                            <StyledThTableCell sx={{minWidth: '100px', width: "calc(100% - 84px - 190px)",}}>Way</StyledThTableCell>
                                                                        </TableRow>
                                                                    </TableBody>
                                                                </Table>
                                                            </WithBorderLeftTableCell>

                                                        </TableRow>
                                                    </StyledTableHead>
                                                    <TableBody>
                                                        {
                                                            stateUpdate ?
                                                                
                                                                (sortedMethodsCurrency(stateUpdate.currencies) || []).map((item: any, id: number) => {
                                                                    return (
                                                                        <TableRow key={id}>

                                                                        <StyledThTableCellAlignTop padding="checkbox" sx={{width: "40px"}}>
                                                                            <StyledCheckbox 
                                                                                checked={item.isAllChecked} 
                                                                                onClick={(e) =>  handleChecngePaymentSystemCurrencyCheckbox(item, e)}
                                                                                disabled={roleForDisableChange}
                                                                            />
                                                                        </StyledThTableCellAlignTop>
                
                                                                        <StyledThTableCellAlignTop sx={{width: "184px"}}>{item.currency}</StyledThTableCellAlignTop>
                
                                                                        <WithBorderLeftTableCellPD sx={{width: "calc(100% - 184px - 40px)"}}>
                                                                            <Table>
                                                                                <TableBody>
                                                                                    {
                                                                                        isClearEnabled 
                                                                                            ? (item.methodsCurrency || []).map((itemCurrency, id: any) => {
                                                                                                return <WithBorderBottomTableRow sx={{width: "100%"}} key={id}>
                                                                                                    <StyledMininizeTableCell sx={{width: "105px", paddingTop: '0', paddingBottom: '0', borderBottom: 'none'}}>
                                                                                                        {
                                                                                                            <SwitchComponent 
                                                                                                                checked={Boolean(itemCurrency.checked)}
                                                                                                                onChange={(e) => handleChangePaymentSystemCurrencyes(itemCurrency, e)}
                                                                                                                label={``}

                                                                                                                disabled={roleForDisableChange}
                                                                                                            />
                                                                                                        }
                                                                                                    </StyledMininizeTableCell>
                                                                                                    <StyledMininizeTableCell sx={{width: "190px", paddingLeft: '0',paddingTop: '6', paddingBottom: '6', borderBottom: 'none'}}>
                                                                                                        {
                                                                                                            itemCurrency?.labels[1].includes('<') 
                                                                                                                ? itemCurrency?.labels[1].substr(0, itemCurrency?.labels[1].indexOf("<"))
                                                                                                                : itemCurrency?.labels[1]
                                                                                                        }
                                                                                                    </StyledMininizeTableCell>
                                                                                                    <StyledMininizeTableCell sx={{width: "calc(100% - 84px - 190px)", paddingTop: '6', paddingBottom: '6', borderBottom: 'none'}}>
                                                                                                        {
                                                                                                            itemCurrency?.labels[2]
                                                                                                        }
                                                                                                    </StyledMininizeTableCell>
                                                                                                </WithBorderBottomTableRow>
                                                                                            })
                                                                                            : (item.methodsCurrency || []).filter(item => item.checked).length > 0 
                                                                                                ? (item.methodsCurrency || []).filter(item => item.checked).map((itemCurrency, id: any) => {
                                                                                                        return <WithBorderBottomTableRow sx={{width: "100%"}} key={id}>
                                                                                                            <StyledMininizeTableCell sx={{width: "105px", paddingTop: '0', paddingBottom: '0', borderBottom: 'none'}}>
                                                                                                                {
                                                                                                                    <SwitchComponent 
                                                                                                                        checked={Boolean(itemCurrency.checked)}
                                                                                                                        onChange={(e) => handleChangePaymentSystemCurrencyes(itemCurrency, e)}
                                                                                                                        label={``}

                                                                                                                        disabled={roleForDisableChange}
                                                                                                                    />
                                                                                                                }
                                                                                                            </StyledMininizeTableCell>
                                                                                                            <StyledMininizeTableCell sx={{width: "190px", paddingLeft: '0',paddingTop: '6', paddingBottom: '6', borderBottom: 'none'}}>
                                                                                                                {
                                                                                                                    itemCurrency?.labels[1]
                                                                                                                }
                                                                                                            </StyledMininizeTableCell>
                                                                                                            <StyledMininizeTableCell sx={{width: "calc(100% - 84px - 190px)", paddingTop: '6', paddingBottom: '6', borderBottom: 'none'}}>
                                                                                                                {
                                                                                                                    itemCurrency?.labels[2]
                                                                                                                }
                                                                                                            </StyledMininizeTableCell>
                                                                                                        </WithBorderBottomTableRow>
                                                                                                    })
                                                                                                : <WithBorderBottomTableRow sx={{width: "100%"}} key={id}>
                                                                                                    <StyledMininizeTableCell sx={{width: "105px", paddingTop: '0', paddingBottom: '0', borderBottom: 'none'}}>
                                                                                                        No checked methods
                                                                                                    </StyledMininizeTableCell>
                                                                                                </WithBorderBottomTableRow>
                                                                                    }
                                                                                    
                                                                                </TableBody>
                                                                            </Table>
                                                                        </WithBorderLeftTableCellPD>
                                                                    </TableRow>
                                                                    )
                                                                })
                                                                
                                                            : <>No data</>
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </StyledPaperTable>
                                    </StyledTabPanel>
                                </TabContext>

                                <StyledModal
                                    open={openCreatePopup}
                                    onClose={() => handleCloseCreatePopup()}
                                >
                                    <StyledBoxPopup>
                                        <CreateNewAccountForPaymentSystem 
                                            onClose={handleCloseCreatePopup}
                                            onCreate={handleCreateAccount}
                                        />
                                    </StyledBoxPopup>
                                </StyledModal>

                                <StyledDialog
                                    open={deleteDialog}
                                    onClose={handleCloseDeleteDialog}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <NotifyDialog
                                        title={` Delete account ${preDeleteAccount ? preDeleteAccount : 'Null'} ?`}
                                        confirmButtonText='YES, DELETE'
                                        cancelButtonText='CANCEL'
                                        onClose={handleCloseDeleteDialog}
                                        width="400px"
                                        isDeleteButton
                                        onCancel={handleCloseDeleteDialog}
                                        onConfirm={handleDeleteAccount}
                                    >
                                        Are you sure you want to delete this account?
                                    </NotifyDialog>  
                                </StyledDialog>
                        </Box>
                    }

                    {
                        (error?.response?.status === 404) && <NotFoundPage />
                    }
                </>
            }

            {isLoading && <Loader />}
        </PageContainer>
    );
};





export default compose(
    connect((state: RootState) => ({
        isLoading: state.userPaymentSystems.isLoading,
        paymentSystem: state.userPaymentSystems.userPaymentSystem,
        error: state.userPaymentSystems.error,
        userRoles: state.currentUser.userData.roles,
        routsUser: state.currentUser.userData.routs,
        isSuperadmin: state.currentUser.userData.superadmin,
    }), {
        getUserPaymentSystemRequest, 
        changeFieldCredentials, 
        changeFieldCurrencies,
        changeFieldCredentialsModeModal,
        changeActiveFieldCredentialsModeModal,
        updateUserPaymentSystem,
        updateUserPaymentSystemWithAccounts,
    })
)(ChangePaynemtSystem);
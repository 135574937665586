import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {CloseSvg} from '../../components/svg/close-svg';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import {Button} from '../../components/button';
// import {SwitchComponent} from '../../components/switch';
import {connect} from 'react-redux';
import {compose} from "redux";
import {RootState} from '../../store/configure-store';
import {createUserPaymentSystemRequest} from '../../actions/userPaymentSystems';
import {getPaymentSystemsListFilteredRequest} from '../../actions/paymentSystems';

import OutlinedInput from '@mui/material/OutlinedInput';
import { Scrollbars } from 'react-custom-scrollbars-2';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
	List,
	ListItem,
	ListItemText,
	Menu,
} from '@mui/material';


const CreatePaymentFormContainer = styled('div')(({ theme }) => (`
    height: 100%;
    max-width: 550px;
    width: 100%;
    box-sizing: border-box;
    background: ${theme.palette.background.paper};
    position: relative;
    padding: 32px 32px 0px 32px;
    align-content: space-between;
    display: flex;
    flex-wrap: wrap;
`));

const CloseSvgWrapper = styled('div')(`
    position: absolute;
    top: 25px;
    right: 25px;
    cursor: pointer;
`);

const StyledMenuItem = styled(MenuItem)(({ theme }) => (`
    &:hover{
        background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
    }
    &.Mui-selected{
        background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
        &:hover{
            background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'};
        };
        &.Mui-focusVisible{
            background: ${theme.palette.mode === 'dark' ? theme.palette.background.default : '#F1F8ED'}
        };
    }
`));

const ButtonsContainer = styled('div')(`
    padding: 20px 0 32px 0;
`);

const WrapFields = styled('div')(({ theme }) => (`
    width: 100%;
`));

const StyledeList = styled('div')(({ theme }) => (` 
	& {		
		@media (max-width: 767px) {
			order: 5;
			width: 100%;
		}
		& > nav{
			background: none;
			margin-right: ${theme.spacing(5)};
			@media (max-width: 767px) {
				margin-right: 0px;
			}	
			& .MuiListItem-root{
				height: 36px;
				border-radius: 4px;	
				padding-right: ${theme.spacing(1)};
				background: ${theme.palette.secondary.main};
				color: ${theme.palette.text.primary};	
				& .MuiListItemText-root{
					margin-left: ${theme.spacing(2)};
					margin-right: ${theme.spacing(5)};
					font-size: 16px;
					& > .MuiTypography-root{
						font-size: 16px;
					}
				}
			}
		}
	} 
`))

const StyledMenu = styled(Menu)(({ theme }) => (` 
	& > .MuiPaper-root{
	 	border-radius: 0;
		width: 427px;
		padding-left: 10px;
		padding-right: 10px;
		color: ${theme.palette.text.primary};
	} 
`));

const StyledOutlinedInput = styled(OutlinedInput)(({ theme }) => (`  
	width: 427px;
	& > input{
		height: 5px;	
		font-size: 14px;
	}  
	&.Mui-focused fieldset{
		border: 1px solid #38AA72 !important;
	}  
`))


const CreateNewPaymentSystem = (props: any) => {
    const {onClose, setSnackbarState, updateData, createUserPaymentSystemRequest, getPaymentSystemsListFilteredRequest, paymentSystemsList} = props;

    const [paymentSystem, setPaymentSystem] = React.useState('');

    const handleClose = () => {
        onClose();
    };

    const handleSubmit = async () => {
        const data = {
            paymentSystem: `/api/payment_systems/${paymentSystem}`,
            active: 1,
        };
        
        try {
            const isCreatedResponse = await createUserPaymentSystemRequest(JSON.stringify(data)); 
            
            if (isCreatedResponse.success) {
                updateData();
                setSnackbarState({
                    open: true,
                    isError: false,
                    message: "Payment system has been added",
                });
            } else {
                setSnackbarState({
                    open: true,
                    isError: true,
                    message: "Error: Payment system was not added",
                });
            }
        } catch (error) {
            setSnackbarState({
                open: true,
                isError: true,
                message: "Network error: please try again",
            });
        }
        
        onClose();
    };

    useEffect(() => {
        getPaymentSystemsListFilteredRequest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);		
	};
  	const open = Boolean(anchorEl);

    const [currentChangeSystem, setCurrentChangeSystem] = useState<string>('Payment system');

	const handleChangePSystem = (item) => {
        setValuseSeatch(item.name);
        setCurrentChangeSystem(item.name);
        setPaymentSystem(item.id)
        setAnchorEl(null);
	};

    const [valuseSeatch, setValuseSeatch] = useState<string>('');
    const handleChangeSearch = (e) => {
        setValuseSeatch(e.target.value)
    }

    let paymentSystemsListFiltersed = paymentSystemsList.filter((item) => {
        return item.name.toLowerCase().includes(valuseSeatch.toLowerCase())
    });

    return (
        <CreatePaymentFormContainer>
            <CloseSvgWrapper>
                <CloseSvg size="14px" onClick={handleClose}/>
            </CloseSvgWrapper>

            <WrapFields>
                <StyledeList>
                    <List
                        component="nav"
                        aria-label="Payment systems"
                    >
                        <ListItem
                            // button
                            id="lock-button"
                            aria-haspopup="listbox"
                            aria-controls="lock-menu"
                            aria-label=""
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClickListItem}
                        >
                            <ListItemText primary={currentChangeSystem}/>
                            <ArrowDropDownIcon />
                        </ListItem>
                    </List>
                </StyledeList>

                <StyledMenu
                    id="lock-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'lock-button',
                        role: 'listbox',
                    }}
                >
                    <FormControl variant="outlined">
                        <StyledOutlinedInput
                            type={'text'}
                            value={valuseSeatch}
                            onChange={(e)=>{handleChangeSearch(e)}}
                            label={``}
                        />
                    </FormControl>
                    <Scrollbars style={{ height: 185, margin: '10px 0', }}>
                        {(paymentSystemsListFiltersed || []).map((item, index) => (
                            <StyledMenuItem
                                key={Math.random()}
                                selected={index === 0}
                                onClick={() => handleChangePSystem(item)}
                            >
                                {item.name}
                            </StyledMenuItem>
                        ))}
                    </Scrollbars>
                </StyledMenu>
            </WrapFields>

            <ButtonsContainer>
                <Button 
                    sx={{marginRight: '12px'}}
                    disabled={!paymentSystem}
                    onClick={handleSubmit}
                >
                    Create
                </Button>
                <Button 
                    variant="outlined"
                    onClick={handleClose}
                >CANCEL
                </Button>
            </ButtonsContainer>

        </CreatePaymentFormContainer>
    )
}

export default compose(
    connect((state: RootState) => ({
        paymentSystemsList: state.paymentSystems.paymentSystemsListFiltered['hydra:member'],
    }), {
        createUserPaymentSystemRequest, 
        getPaymentSystemsListFilteredRequest,
    })
)(CreateNewPaymentSystem)

